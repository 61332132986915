/* eslint-disable no-param-reassign */
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import store from 'redux-v2/store';
import { v4 as uuidv4 } from 'uuid';

import {
  ColumnDataTypes,
  ColumnModes,
  DimensionMode,
  DimensionStatus,
  draggableItemType,
  FieldEntitiesType,
  MAX_DESC_CHARACTER_LIMIT,
  MAX_NAME_CHARACTER_LIMIT,
  MIN_CHARACTER_LIMIT,
  OperatorTypes,
} from 'core/constants/report';
import {
  IBuilder,
  IBuilderDimension,
  IBuilderFilter,
  IBuilderMeasure,
  IDimensionsMetaFields,
  IFilterGroup,
  IFilterGroupRender,
  IHierarchy,
  IReportBuilderColumn,
  IReportConfig,
  ISetDimensionsBinningPayload,
  ISorting,
  ITemplateList,
  ReportBuilderStatus,
  ReportType,
} from 'core/models/report-builder/report-builder';
import {
  IExpression, isAgg, newArrayJoinExpr, newColumnExpr, newDateExpr,
} from 'core/models/report-expressions';
import { IDimension, ISetGroupingPayload } from 'core/models/report-redux';

import {
  IColumn, IColumnDimension, IColumnProperties, IField, IFilterConfig,
} from 'core/models/report-response';
import { FilterType, GroupFilterId, UserFilterPayloadType } from 'core/constants/filter';
import { FilterModel, ObjModel, ReportResponseModel } from 'core/models';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import { IVariables } from 'core/models/store';
import {
  areDimensionsEqual,
  getDefaultMeasures,
  getUniqIdentiferForDrilldownCols,
  getUpdateVisualization,
  isDefaultUserField,
  isDimensionCustom,
  isDrilldownPossible,
  isMeasureAndApplied,
  isMeasureCustom,
  isMeasureDynamic,
  isWrongInput,
  rebuildSortState,
  setDrilldownEnableFlag,
  sortColumns,
  validateAndAppendCountToName,
} from 'core/utils/report.util';
import { DateBinningFormatters } from 'core/constants/formatters';
import { getFilterConfigFromTemplate, removeDuplicateObject } from 'core/utils/common.utility';
import { checkIsUserSalesGroupingField } from 'components/feature/Report/ReportSidebar/Settings/settings.utils';
import { IDateRangeTypes } from 'core/constants/date';
import { getDatesIfAbsoluteRangeIsRestricted, getDatesIfRelativeRangeIsRestricted } from 'core/utils/date.util';
import moment from 'moment-timezone';
import { buildCustomMeasure, getDataTypeFromCustomColumn } from 'core/utils/report-expressions';
import { isValidArray, isValidArrayAndNotEmpty, isValidObjectAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { ObjGeneric } from 'core/models/obj';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import { getInitialVisualization } from 'redux-v2/report/report-store.utils';
import { ModuleName } from 'redux-v2/settings-store/settings-store-state';
import { getEntityJoinIdForCustomMeasure } from 'components/feature/ReportBuilderV2/ReportBuilderDetail/DrilldownTabContent/drilldown.util';
import {
  userDefaultFilterValue, UserDelimiter, UserHierarchy, UserNameConstant,
} from 'components/feature/Report/ReportSidebar/common/constants';
import { ISwitchUserFieldFilterPayload, SwitchContext } from 'core/models/report-builder/report-builder-redux';
import { isValid } from 'date-fns';
import { saveReportConfig } from 'redux-v2/report/report-store.requests';
import {
  IReportBuilderState, IFilterBuilder, IFilterMetaInfo, IFilterMetaInfoStore, ICustomExpression, IAddMeasurePayload, IRemoveMeasurePayload, IMoveMeasurePayload, ISetMeasurePayload, DrilldownConfigMapping, DrilldownColumnConfig, DrilldownColumnConfigResponse, DrilldownFieldUpdationType, DrilldownFieldUpdationsAction, IdrilldownConfigInfo, DrillDownColumnConfigObj,
  IDrilldownSorting,
  ISortDrilldownColumnPayload,
  ExpressionContext,
  MeasureListWithJoinId,
} from './report-builder-store.state';
import { builderMeasuresListSelector, defaultEntitySelector, fieldSelectors } from './report-builder-store.selectors';
import { IGlobalState } from '../../core/models/redux';

// Update the selected report template with type as custom and status as draft
// @templateItem - Selected template
export const selectedReportTemplate = (
  templateItem: ITemplateList,
): IReportConfig => {
  const { ReportConfig } = templateItem;
  const { FilterMetaInfo } = templateItem;
  if (isValidArrayAndNotEmpty(FilterMetaInfo)) {
    const groupIdFilterList = FilterMetaInfo.filter((filterInfo) => filterInfo.ID === GroupFilterId);
    if (isValidArrayAndNotEmpty(groupIdFilterList)) {
      const groupIdFilter = groupIdFilterList[0];
      const render: IFilterGroupRender = {
        ID: groupIdFilter.ID,
        Type: groupIdFilter.Type,
        DefaultValue: null,
        LinkedTo: groupIdFilter.LinkedTo,
        Label: groupIdFilter.Label,
        Metadata: groupIdFilter.Metadata,
        StyleConfig: null,
      };
      const updatedGroupIdFilter = {
        ...groupIdFilter,
        IsAdditional: false,
        AlwaysEnabled: false,
        DataType: ColumnDataTypes.String,
        Render: render,
      };

      if (isValidArrayAndNotEmpty(ReportConfig?.Builder)) {
        const reportConfigCopy = cloneDeep(ReportConfig);
        if (!reportConfigCopy.Builder[0]?.Filter?.FilterGroup?.some((config) => config.ID === updatedGroupIdFilter.ID)) {
          reportConfigCopy.Builder[0].Filter.FilterGroup.push(updatedGroupIdFilter);
        }

        reportConfigCopy.Status = ReportBuilderStatus.DRAFT;
        reportConfigCopy.Details = {
          ...reportConfigCopy.Details,
          Type: ReportType.Custom,
        };

        return reportConfigCopy;
      }
    }
  }
  return {
    ...ReportConfig,
    Status: ReportBuilderStatus.DRAFT,
    Details: {
      ...ReportConfig.Details,
      Type: ReportType.Custom,
    },
  };
};

// Update the report config with report ID and Title, description to empty
// @state - Report builder state
// @payload - Report ID
export const getUpdatedPayloadAfterInitialSave = (
  state: IReportBuilderState,
  payload: string,
): IReportConfig => {
  const reportBuilderPayload = state.updatedReportBuilderInfo.reportBuilderSavePayload;
  const updatedVisualization = getInitialVisualization(reportBuilderPayload?.Visualization, false);
  return {
    ...reportBuilderPayload,
    ReportConfigId: payload,
    Details: {
      ...reportBuilderPayload.Details,
      Title: '',
      Description: '',
    },
    Visualization: {
      Type: updatedVisualization.type,
      Builder: { ...reportBuilderPayload.Visualization.Builder, Properties: updatedVisualization.attributes },
    },
  };
};

// Validation check for the report builder save.
// @Title - Title of the report builder form
// @Description - Description of the report builder form
// @state - Report builder state
export const getIsReportBuilderSaveFormDisable = (
  Title: string,
  Description: string,
  state: IReportBuilderState,
) => isWrongInput(Title)
  || isWrongInput(Description)
  || Title.length > MAX_NAME_CHARACTER_LIMIT
  || Description.length > MAX_DESC_CHARACTER_LIMIT
  || Title.trim().length < MIN_CHARACTER_LIMIT
  || Description.trim().length < MIN_CHARACTER_LIMIT
  || !Title.trim().length
  || !Description.trim().length
  || (isEqual(
    state.updatedReportBuilderInfo.selectedTemplate.ReportConfig,
    state.updatedReportBuilderInfo.reportBuilderSavePayload,
  ) && !state.reportEditInfo.isEditing);

// Validation specific to filter block.
// @filterConfig - Configuration of the filter
// @appliedFilters - Filter response and data
export const getIsFilterFormDisable = ({
  filterConfig,
  appliedFilters,
}: IFilterBuilder) => {
  const addedFilterConfig = filterConfig.filter((el) => !el.IsRemoved);
  const dropdownFilterIds = addedFilterConfig
    .filter(
      (config: IFilterConfig) => ![
        FilterType.PRE_APPLIED,
        FilterType.MultiTextSearch,
        FilterType.DateRange,
        FilterType.UserMultiSelect,
      ].includes(config.Type),
    )
    .map((item: IFilterConfig) => item.ID);

  const userFieldsConfig = addedFilterConfig.filter(
    (config: IFilterConfig) => config.Type === FilterType.UserMultiSelect,
  );
  const isUserFieldValueEmpty = userFieldsConfig?.some(
    (config: IFilterConfig) => appliedFilters[config.ID]?.FilterResponse?.Type
        === UserFilterPayloadType.In
      && isEmpty(appliedFilters[config.ID]?.FilterResponse?.UserIds),
  );
  return (
    isUserFieldValueEmpty
    || dropdownFilterIds.some((id: string) => isEmpty(appliedFilters[id]?.FilterResponse))
  );
};

export const getAppliedDimensions = (
  state: IReportBuilderState,
  reportConfig: IReportConfig,
  filteMetaInfo?: IFilterConfig[],
): IDimension[] => {
  const { Columns } = reportConfig;
  const availableDimensions: IDimension[] = [];

  const appliedUserFilter = isValidArrayAndNotEmpty(filteMetaInfo) && filteMetaInfo?.find((filter:IFilterMetaInfo | IFilterConfig) => filter?.Type === FilterType.UserMultiSelect);

  Columns.forEach((item: IReportBuilderColumn) => {
    const isDefaultUserSwitchField = isDefaultUserField(item, appliedUserFilter);
    if (item.Props.Dimension || item.Props.ParentDimension) {
      const dimension = {
        Name: item.Name,
        ReferTo: item.Props.ReferTo,
        ...item.Props.Dimension,
        BuilderConfig: { ...item.BuilderConfig, Entity: isDefaultUserSwitchField ? appliedUserFilter.Entity : item?.BuilderConfig?.Entity },
        IsMasked: item.Props.IsMasked,
        Mode: item.Props?.Mode,
        Props: item.Props,
        IsUserDynamicField: item?.Props?.IsUserDynamicField,
        // For metafields Applied does not exists so setting NotApplied as default
        Applied: item?.Props?.Dimension
          ? item?.Props?.Dimension?.Applied
          : DimensionStatus.NotApplied,
        IsFieldSwitch: item?.Props?.ParentDimension ? isDefaultUserField(Columns.find((col) => item?.Props?.ParentDimension === col?.Props?.ReferTo), appliedUserFilter) : isDefaultUserSwitchField,
      } as IDimension;
      if (
        item.BuilderConfig?.DataType === ColumnDataTypes.DateTime
        && !item.Props.Dimension.DimensionProp
      ) {
        // set default date binning props for date dimensions in template
        dimension.DimensionProp = {
          DataType: item.BuilderConfig.DataType,
          Props: { BinningType: DateBinningFormatters.NONE },
        };
        dimension.Props.Dimension.DimensionProp = {
          ...dimension.DimensionProp,
          Props: {
            ...dimension.DimensionProp.Props,
          },
        };
      }
      availableDimensions.push(dimension);
    }
  });
  return reorderAppliedDimensions(availableDimensions);
};

export const reOrderDimensions = (
  appliedDimensions: IDimension[],
): IDimension[] => {
  const rowGroupingDimensions = appliedDimensions?.filter(
    (dim) => dim?.DimensionMode !== DimensionMode.ColumnGroup
      && dim?.Applied !== DimensionStatus.NotApplied,
  );
  const colGroupingDimensions = appliedDimensions?.filter(
    (dim) => dim?.DimensionMode !== DimensionMode.RowGroup
      && dim?.Applied !== DimensionStatus.NotApplied,
  );
  const availableDimensions = appliedDimensions?.filter(
    (dim) => dim?.Applied === DimensionStatus.NotApplied,
  );
  return [
    ...rowGroupingDimensions,
    ...colGroupingDimensions,
    ...availableDimensions,
  ];
};

export const getMeasureColumns = (
  reportConfig: IReportConfig,
  takeOnlyApplied?: boolean,
  removeCustomMeasures: boolean = false,
): IColumn[] => {
  const {
    Columns: columns, Builder: builders,
  } = reportConfig;
  const measures = builders
    ?.map((builder) => builder.Select.Measures)
    ?.concat(reportConfig.CalculatedColumns ?? [])
    ?.flat();

  const updatedColumns = removeCustomMeasures ? columns?.filter((col: IColumn) => !col.Props.Measure?.IsCustomExpression) : columns;
  return updatedColumns
    ?.filter((column) => (!column.Props?.Dimension && !column?.Props?.ParentDimension) && (!takeOnlyApplied
        || (column?.Props?.Mode !== ColumnModes.NotApplied
          && column?.Props?.Mode !== ColumnModes.Hidden)))
    ?.map((column): IReportBuilderColumn => {
      const measure = measures.find((measureItem) => measureItem.Alias === column.Name);
      return {
        ...column,
        Props: {
          ...column.Props,
          Measure: {
            // incoming measures don't have the Measure object which we need for isMeasure and others
            Name: column.Name,
            IsMeasure: true,
            IsCustomExpression: column?.Props?.Measure?.IsCustomExpression || undefined,
            Expression: column?.Props?.Measure?.Expression || undefined,
            Entity: column?.Props?.Measure?.Entity,
            IsPreDefined: column?.Props?.Measure?.IsPreDefined,
            JoinID: column?.Props?.Measure?.JoinID,
          },
        },
        BuilderConfig: {
          ...column.BuilderConfig,
          DataType: measure?.DataType || ColumnDataTypes.Default,
        },
      };
    });
};

export const getSavePayloadDimensionColumns = (
  availableDimensions: IDimension[],
): Array<IReportBuilderColumn> => {
  const columns: Array<IReportBuilderColumn> = [];
  const appliedSwitchField = isValidArrayAndNotEmpty(availableDimensions) && availableDimensions.find((dim:IDimension) => dim?.IsFieldSwitch && dim?.Applied === DimensionStatus.Applied);
  availableDimensions?.forEach((dim: IDimension) => {
    let shouldExcludeColumn = false;
    // pop out unapplied switch field
    if (appliedSwitchField && !isEqual(appliedSwitchField, dim) && dim?.IsFieldSwitch && dim?.Applied === DimensionStatus.NotApplied) {
      shouldExcludeColumn = true;
    }
    if (
      (dim.Applied !== DimensionStatus.NotApplied || (!dim.Props.IsAdditional && !dim?.BuilderConfig?.IsDynamicField))
      && !dim?.Props?.ParentDimension && !shouldExcludeColumn
    ) {
      const isDynamicField = dim?.BuilderConfig?.IsDynamicField && !dim?.Props?.ReferTo;
      columns.push({
        Name: isDynamicField ? dim.BuilderConfig.DisplayName : dim.Name,
        Props: {
          ...dim.Props,
          Dimension: getDimensionProps(dim, dim.Props),
          Mode: dim.Mode,
          IsAdditional: isDynamicField || dim?.Props?.Dimension?.IsCustomExpression || dim?.Props?.IsUserDynamicField ? true : dim?.Props?.IsAdditional || false,
        },
      });
      // For Join template there can be duplicate parentDimension so adding extra ReferTo checks as well.
      const referredDim = availableDimensions.find(
        (item: IDimension) => dim.Name === item?.Props?.ParentDimension && dim?.ReferTo === item?.Name,
      );
      if (referredDim) {
        columns.push({
          Name: referredDim.Name,
          Props: referredDim.Props,
        });
      }
    }
  });
  columns.filter((item) => {
    if (item?.Props?.IsAdditional && item?.Props?.Dimension?.Applied === DimensionStatus.NotApplied) {
      return true;
    }
    if (columns?.find((col) => col.Name === item.Props.ReferTo && col.Props.IsAdditional && col.Props.Dimension.Applied === DimensionStatus.NotApplied)) {
      return true;
    }
    return false;
  });
  return columns;
};

export const getSavePayloadMeasureColumns = (
  appliedMeasures: IColumn[],
  defaultMeasures: IReportBuilderColumn[],
  report: IReportConfig,
): IColumn[] => {
  const measures: IColumn[] = [];
  const isCalculated = fromPairs((report.CalculatedColumns || []).map((c) => [c.Alias, true]));
  appliedMeasures.forEach((measure) => {
    if (isMeasureAndApplied(measure)) {
      measures.push({
        Name: measure.Name,
        Props: {
          ...measure.Props,
          Measure: isMeasureCustom(measure)
            ? { ...measure?.Props?.Measure, Expression: undefined } // Expression already added to Report Config
            : undefined, // For pre-defined measures Measure expression will be null/ undefined
          IsAdditional: isMeasureDynamic(measure) || isMeasureCustom(measure),
          DrilldownEnabled: isCalculated[measure.Name]
            && (isMeasureCustom(measure) || isAgg(measure?.Props?.Measure?.Expression ?? {} as IExpression)) ? false : measure?.Props?.DrilldownEnabled,
        },
      });
    }
  });

  defaultMeasures
    .filter((item) => !measures.find((measure) => measure.Name === item.Name) && !item?.Props?.IsAdditional)
    // Filter Not applied pre defined measures
    .forEach((measure) => measures.push({
      Name: measure.Name,
      Props: {
        ...measure.Props,
        Mode: ColumnModes.NotApplied,
        Measure: undefined,
      },
    }));
  return measures;
};

export const getSavePayloadBuilderDimensions = (
  appliedDimensions: IDimension[],
  builderDimensions: IBuilderDimension[],
  NameSpaceInfo?: Object,
  Namespace?: string,
  isAugumented?: boolean,
): Array<IBuilderDimension> => {
  const unappliedDefaultUserField = isValidArrayAndNotEmpty(appliedDimensions) && appliedDimensions.find((item:IDimension) => item?.IsFieldSwitch && item?.Applied !== DimensionStatus.Applied && !item?.BuilderConfig?.IsDynamicField);
  const newBuilderDimensions = isValidArrayAndNotEmpty(builderDimensions) && builderDimensions.filter((item: IBuilderDimension) => !item.IsAdditional && unappliedDefaultUserField?.Name !== item?.Alias);
  appliedDimensions?.forEach((dim: IDimension) => {
    // For reports updating the binning value of already applied dimensions
    if (dim?.Applied === DimensionStatus.Applied && dim?.BuilderConfig?.DataType === ColumnDataTypes.DateTime) {
      newBuilderDimensions?.forEach((item) => {
        if (item.Alias === dim.Name) {
          item.GroupByExpression.SubType = dim?.DimensionProp?.Props?.BinningType as DateBinningFormatters;
        }
      });
    }

    if ((dim.Applied !== DimensionStatus.NotApplied || (!dim.Props.IsAdditional && !dim?.BuilderConfig?.IsDynamicField && !dim?.IsFieldSwitch))
      && !dim?.Props?.ParentDimension
      && !newBuilderDimensions?.find(
        (item: IBuilderDimension) => item.Alias === dim.Name,
      )
    ) {
      const isDynamicField = dim?.BuilderConfig?.IsDynamicField && !dim?.Props?.ReferTo;
      const updatedAlias = isAugumented && (dim.Props.IsAdditional || dim?.BuilderConfig?.IsDynamicField)
        ? getUpdatedAlias(
          NameSpaceInfo[Namespace as keyof object],
          dim?.BuilderConfig?.SchemaName,
          dim.BuilderConfig?.Entity,
        )
        : dim?.BuilderConfig?.Alias;
      if (dim.IsCustomExpression && dim.Expression) {
        newBuilderDimensions.push({
          GroupByExpression: dim.Expression,
          Alias: dim.Name,
          IsTypeAlteringExpression: false,
          DataType: dim?.BuilderConfig?.DataType,
          IsAdditional: true,
        });
      } else if (checkIsUserSalesGroupingField(dim)) {
        // For Dynamic having no metafield we pass the Name
        newBuilderDimensions?.push({
          GroupByExpression: newArrayJoinExpr(newColumnExpr(
            isDynamicField ? dim.BuilderConfig.DisplayName : dim.Name,
            updatedAlias,
            dim?.BuilderConfig?.DataType,
            dim?.BuilderConfig?.JoinID,
            dim?.BuilderConfig?.Entity,
            dim?.IsFieldSwitch,
          )),
          Alias: isDynamicField ? dim?.BuilderConfig.DisplayName : dim.Name,
          IsTypeAlteringExpression: false,
          DataType: dim?.BuilderConfig?.DataType,
          IsAdditional: true,
        });
      } else if (dim.BuilderConfig.DataType === ColumnDataTypes.DateTime) {
        newBuilderDimensions.push({
          GroupByExpression: newDateExpr(
            dim?.DimensionProp?.Props?.BinningType as DateBinningFormatters,
            newColumnExpr(
              isDynamicField ? dim?.BuilderConfig?.DisplayName : dim.Name,
              updatedAlias,
              dim?.BuilderConfig?.DataType,
              dim?.BuilderConfig?.JoinID,
              dim?.BuilderConfig?.Entity,
              dim?.IsFieldSwitch,
            ),
          ),
          Alias: isDynamicField ? dim?.BuilderConfig?.DisplayName : dim.Name,
          IsTypeAlteringExpression: false,
          DataType: dim?.BuilderConfig?.DataType,
          IsAdditional: true,
        });
      } else {
        newBuilderDimensions.push({
          GroupByExpression: newColumnExpr(
            isDynamicField ? dim.BuilderConfig.DisplayName : dim.Name,
            updatedAlias,
            dim?.BuilderConfig?.DataType,
            dim?.BuilderConfig?.JoinID,
            dim?.BuilderConfig?.Entity,
            dim?.IsFieldSwitch || !dim?.IsUserDynamicField,
          ),
          Alias: isDynamicField ? dim.BuilderConfig.DisplayName : dim.Name,
          IsTypeAlteringExpression: false,
          DataType: dim?.BuilderConfig?.DataType,
          IsAdditional: true,
        });
      }
    }
  });

  return newBuilderDimensions;
};

export const getSavePayloadBuilderMeasures = (
  appliedMeasures: IColumn[],
  originalMeasures: IBuilderMeasure[],
): IBuilderMeasure[] => {
  const builderMeasures: IBuilderMeasure[] = [];
  appliedMeasures.forEach((measure) => {
    if (isMeasureDynamic(measure)) {
      builderMeasures.push({
        Alias: measure.Name,
        DataType: measure?.BuilderConfig?.DataType,
        MeasureExpression: { ...measure.Props.Measure.Expression },
        IsGrandTotal: false,
        DrillDownSettings: undefined,
        IsCustomExpression: measure?.Props?.Measure?.IsCustomExpression,
        IsAdditional: true,
      });
    }
  });

  originalMeasures.forEach((measure) => {
    if (!builderMeasures?.find((m) => measure?.Alias === m?.Alias) && (!measure.IsAdditional || !!appliedMeasures.find((m) => m.Name === measure.Alias))) {
      builderMeasures.push(measure);
    }
  });
  return builderMeasures.filter((m) => !!m);
};

// Filter value based on the filter type (Part of the template)
// @item - Filter group part of the report raw config
// @appliedFilters - Filter response and data
export const getDefaultValue = (
  item: IFilterGroup,
  appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
) => {
  if (item.Render.Type === FilterType.UserMultiSelect) {
    return appliedFilters[item.ID]?.FilterResponse.UserIds;
  }
  if (item.Render.Type === FilterType.PRE_APPLIED) {
    return item.Render.DefaultValue;
  }
  if (
    item.Render.Type === FilterType.LSQMetadataMultiSelect
    || item.Render.Type === FilterType.GroupMultiSelect
    || item.Render.Type === FilterType.CustomDefinedMultiSelect
  ) {
    return appliedFilters[item.ID]?.FilterResponse?.length
      === appliedFilters[item.ID]?.FilterOptions?.length
      ? null
      : appliedFilters[item.ID]?.FilterResponse;
  }
  return appliedFilters[item.ID]?.FilterResponse;
};

// Update the filter default value and label of the filter group in report raw config
// @filterGroup - Filter group part of the report raw config
// @appliedFilters - Filter response and data
export const updateReportTemplateFilterBuilder = (
  filterGroup: IFilterGroup[],
  appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
  filterConfig: Array<IFilterConfig>,
  userFilterGroup:IFilterGroup,
): IFilterGroup[] => {
  let relatedFilter: IFilterGroup = null;
  const containsUserFilter = isValidArrayAndNotEmpty(filterGroup) && filterGroup.findIndex((item: IFilterGroup) => item?.Render?.Type === FilterType.UserMultiSelect) > -1;
  if (!isEmpty(userFilterGroup)) filterGroup.push(userFilterGroup);
  const result = (filterGroup.map((item: IFilterGroup) => {
    const filter = filterConfig.find((filterItem:IFilterConfig) => item.ID === filterItem.ID);
    if (filter) {
      if (userFilterGroup) {
        relatedFilter = filterGroup.find((filterGroupItem:IFilterGroup) => filterGroupItem.Alias === item.Alias && filterGroupItem.ID !== item.ID);
      }
      const Status = item.Render.Type === FilterType.UserMultiSelect ? appliedFilters?.[item.ID]?.userStatus : undefined;
      return ({
        ...item,
        Alias: item.AlwaysEnabled && (item.Render.Type === FilterType.UserMultiSelect || relatedFilter) && containsUserFilter ? userFilterGroup?.Alias || item.Alias : item.Alias,
        IsUserIDFilter: item?.IsUserIDFilter || filter?.IsUserIDFilter,
        JoinID: filter?.JoinID || item?.JoinID,
        LinkedDimensionAlias: item.AlwaysEnabled && (item.Render.Type !== FilterType.UserMultiSelect && relatedFilter) && containsUserFilter ? userFilterGroup?.Alias : item.LinkedDimensionAlias,
        Render: {
          ...item.Render,
          DefaultValue: getDefaultValue(item, appliedFilters),
          Label: appliedFilters[item.ID]?.label || item.Render.Label,
          Metadata: {
            ...item.Render.Metadata,
            ...(Status ? { Status } : {}),
          },
        },
      });
    }
    return null;
  }).filter((group:IFilterGroup) => !isEmpty(group)));

  return result;
};

// Update the sub filters default value and label of the filter group in report raw config (Part of the template)
// @subFilterArray - Sub Filter array part of the report raw config
// @appliedFilters - Filter response and data
export const getSubFilterBuilderConfig = (
  subFilterArray: Array<IBuilderFilter>,
  appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
  filterConfig: Array<IFilterConfig>,
  userFilterGroup?: IFilterGroup,
) => {
  const a = cloneDeep(subFilterArray);
  a?.forEach((subFilter: IBuilderFilter) => {
    // eslint-disable-next-line no-param-reassign
    subFilter.FilterGroup = updateReportTemplateFilterBuilder(
      subFilter.FilterGroup,
      appliedFilters,
      filterConfig,
      userFilterGroup,
    );

    // eslint-disable-next-line no-param-reassign
    subFilter.SubFilters = getSubFilterBuilderConfig(
      subFilter.SubFilters,
      appliedFilters,
      filterConfig,
      userFilterGroup,
    );
  });
  return a;
};

// Returns updated filter group for the report raw config payload on save.
// @filterConfig - Configuration of the filter
// @appliedFilters - Filter response and data
// @filter - Filter group part of the report raw config
export const getSavePayloadBuilderFilters = (
  filterConfig: IFilterConfig[],
  appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
  filter: IBuilderFilter,
): IBuilderFilter => {
  const nonAdditionalFilterGroup = filter.FilterGroup.filter((item) => item.IsAdditional !== true);
  const reportTemplateFilters = updateReportTemplateFilterBuilder(
    nonAdditionalFilterGroup,
    appliedFilters,
    filterConfig,
    null,
  );

  const filterGroupData = [
    ...reportTemplateFilters,
    ...getFilterGroupDetail(
      filterConfig.filter((item) => item.IsDynamic === true),
      appliedFilters,
    ),
  ];
  const removedDuplicateItems = removeDuplicateObject(filterGroupData, 'ID');
  const userFilterGroup = removedDuplicateItems.find((item: IFilterGroup) => item.Render.Type === FilterType.UserMultiSelect);
  return {
    FilterGroup: isValidArrayAndNotEmpty(removedDuplicateItems) ? [...removedDuplicateItems.filter((filterItem:IFilterGroup) => filterItem.Render.Type !== FilterType.UserMultiSelect)] : [],
    GroupOperator: 'AND',
    SubFilters:
      filter.SubFilters !== null
        ? getSubFilterBuilderConfig(filter.SubFilters, appliedFilters, filterConfig, userFilterGroup)
        : null,
  };
};

// Create filter group part of report raw config for the additional filter added
// @filterConfig - Configuration of the filter
// @appliedFilters - Filter response and data
export const getFilterGroupDetail = (
  filterConfig: Array<IFilterConfig>,
  appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
): IFilterGroup[] => {
  const builderFilters: Array<IFilterGroup> = [];
  filterConfig.forEach((config: IFilterConfig) => {
    const {
      AlwaysEnabled,
      LinkedDimensionAlias,
      ID,
      Alias,
      JoinID,
      DataType,
      Type,
      Metadata,
      LinkedTo,
      Label,
      StyleConfig,
      IsDynamic,
      LinkedPreAppliedFilterId,
    } = config;

    let value;

    if (
      Type === FilterType.LSQMetadataMultiSelect
      || Type === FilterType.GroupMultiSelect
      || Type === FilterType.CustomDefinedMultiSelect
    ) {
      value = appliedFilters[ID]?.FilterResponse?.length
        === appliedFilters[ID]?.FilterOptions?.length
        ? null
        : appliedFilters[ID]?.FilterResponse;
    } else {
      value = appliedFilters[ID]?.FilterResponse;
    }
    builderFilters.push({
      LinkedDimensionAlias,
      AlwaysEnabled,
      ID,
      Alias,
      JoinID,
      DataType,
      IsAdditional: IsDynamic,
      Operator: getOperatorValue(Type),
      Render: {
        ID,
        Type,
        DefaultValue: value,
        Metadata,
        LinkedTo: LinkedPreAppliedFilterId || LinkedTo,
        Label,
        StyleConfig,
      },
    });
  });
  return builderFilters;
};

// Decides filter operator based on the filter type
// @Type - Filter Type
export const getOperatorValue = (Type: FilterType) => {
  if (Type === FilterType.PRE_APPLIED) {
    return OperatorTypes.EQ;
  }
  if (Type === FilterType.DateRange) {
    return OperatorTypes.BETWEEN;
  }
  return OperatorTypes.IN;
};

// Convert to filter config based on the filter group of report raw  config
// @builderFilter - Filter group part of the report raw config
// @filterMetaInformation - Additional filter information shared inside the report template
export const filterBuilderToFilterConfig = (
  builderFilter: IBuilderFilter,
  filterMetaInformation?: IFilterMetaInfo[],
  partitionFilters?: Array<string>,
) => {
  const filterConfig: IFilterConfig[] = [];
  builderFilter.FilterGroup.forEach((filterItem: IFilterGroup) => {
    const {
      Render: {
        Label,
        DefaultValue,
        LinkedTo,
        ID,
        StyleConfig,
        Type,
        Metadata,
      },
      LinkedDimensionAlias,
      Alias,
      JoinID,
      DataType,
      AlwaysEnabled,
    } = filterItem;

    const entityInfo: { [key: string]: IFilterMetaInfoStore } = filterMetaInformation?.reduce(
      (
        obj: { [key: string]: IFilterMetaInfoStore },
        item: IFilterMetaInfo,
      ) => {
        obj[item.ID] = {
          Entity: item?.Entity,
          IsPartitionColumn: item.IsPartitionColumn,
          Metadata: item.Metadata,
          LinkedTo: item.LinkedTo,
        };
        return obj;
      },
      {},
    );

    const config: IFilterConfig = {
      SchemaName: '',
      Label,
      DefaultValue,
      ID,
      JoinID,
      LinkedTo: (entityInfo && entityInfo[ID]?.LinkedTo) || LinkedTo,
      StyleConfig,
      Type,
      LinkedDimensionAlias,
      IsDynamic: false,
      Alias,
      Entity: entityInfo && entityInfo[ID]?.Entity,
      IsRemoved: false,
      IsMasked: false,
      DataType,
      Metadata: entityInfo && entityInfo[ID]?.Metadata
        ? { ...entityInfo[ID]?.Metadata }
        : { ...Metadata },
      AlwaysEnabled,
      IsPartitionColumn: (entityInfo && entityInfo[ID]?.IsPartitionColumn) || (partitionFilters && partitionFilters.includes(ID)),
    };
    filterConfig.push(config);
  });

  if (builderFilter.SubFilters !== null) {
    builderFilter.SubFilters.forEach((subFilter: IBuilderFilter) => {
      const subFiltersArray = filterBuilderToFilterConfig(
        subFilter,
        filterMetaInformation,
      );
      filterConfig.push(...subFiltersArray);
    });
  }

  return filterConfig;
};

// Returns applied filter based on the filter group of report raw  config
// @state - Report builder state
// @variables - can be fetched from the auth store
export const getAppliedFilterFromTemplate = (
  state: IReportBuilderState,
  variables?: IVariables,
  reportFilterConfig?: IFilterConfig[],
  filterMetaInfoType?: UserFilterPayloadType,
) => {
  const newAppliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse> = {};
  const fetchFilter = new FilterFetchUtility(variables);
  const filterConfig = reportFilterConfig || getFilterConfigFromTemplate(state.updatedReportBuilderInfo.reportBuilderSavePayload, state.filterBuilder.filterMetaInfo);
  filterConfig.forEach((item) => {
    newAppliedFilters[item.ID] = fetchFilter.GetDefaultSingleFilter(
      item,
      item.Type === FilterType.UserMultiSelect
        ? {
          Type: filterMetaInfoType || UserFilterPayloadType.All,
          UserIds: item.DefaultValue,
        }
        : item.DefaultValue,
    );
    newAppliedFilters[item.ID] = {
      ...newAppliedFilters[item.ID],
      label: item.Label,
    };
  });
  return newAppliedFilters;
};

export const getDimensionProps = (
  dim: IDimension,
  props: IColumnProperties,
): IColumnDimension => ({
  ...props?.Dimension,
  Applied: dim?.Applied,
  Orderable: dim?.Orderable,
  DimensionProp: dim?.DimensionProp,
  DimensionMode: dim?.DimensionMode,
  Expression: dim?.Expression,
  IsCustomExpression: dim.IsCustomExpression,
  CustomEntity: dim.IsCustomExpression ? FieldEntitiesType.Custom : undefined,
});

// Return the unapplied Custom Measures from report builder State
// @expressionStore: Report Builder Expression Store
// @payload: Updated Measure Info
export const getUnappliedCustomMeasures = (expressionStore: ObjModel.ObjGeneric<ICustomExpression>):IColumn[] => {
  const unAppliedCustomMeasures:IColumn[] = [];

  Object.keys(expressionStore || {}).forEach((expKey) => {
    if (expressionStore[expKey].Mode === ColumnModes.NotApplied) { // all the unapplied ones will have the mode 3(Not Applied)
      const exp = expressionStore[expKey];
      const unapppliedCusMes = buildCustomMeasure(exp.displayName, exp.Mode, exp.jsonExpression, exp.uniqueId, exp.Formatter, exp.DataType);
      delete unapppliedCusMes.BuilderConfig;
      unAppliedCustomMeasures.push(unapppliedCusMes);
    }
  });

  return unAppliedCustomMeasures;
};

// Generate the report raw config which is payload for the report builder save.
export const getSaveReportConfigPayload = (state?:IReportBuilderState): IReportConfig => {
  const newState = cloneDeep(state) || store.getState().reportBuilder as IReportBuilderState;
  const partitionColumnFilterDateLimit = store?.getState()?.settings?.data?.Configuration[ModuleName.ReportBuilder].Settings?.Filter?.StringFieldPartitionFilterLimit;
  const template = newState.updatedReportBuilderInfo.selectedTemplate;
  const savePayloadReportConfig: IReportConfig = cloneDeep(newState.updatedReportBuilderInfo.reportBuilderSavePayload);
  let defaultBuilderMeasures: IBuilderMeasure[] = [];
  const addedFilter = newState.filterBuilder.filterConfig.filter(
    (config: IFilterConfig) => config.IsRemoved !== true,
  );
  const appliedDimensions = newState.updatedReportBuilderInfo.appliedDimensions;
  const appliedUserDimension = appliedDimensions.find((dim: IDimension) => dim.Applied === DimensionStatus.Applied && dim.IsFieldSwitch);
  const newAppliedFilters = newState?.filterBuilder?.appliedFilters;
  if (isStringFieldApplied(appliedDimensions)) {
    getUpdatedAppliedFiltersForPartitionColumn(
      newState.filterBuilder.filterConfig,
      newAppliedFilters,
      partitionColumnFilterDateLimit,
    );
  }

  savePayloadReportConfig.Builder = savePayloadReportConfig.Builder.map(
    (item, builderIdx) => {
      defaultBuilderMeasures = [...defaultBuilderMeasures, ...item.Select.Measures];
      if (savePayloadReportConfig?.CalculatedColumns) {
        defaultBuilderMeasures = defaultBuilderMeasures.concat(savePayloadReportConfig.CalculatedColumns).flat();
      }
      return ({
        ...item,
        Select: {
          ...item.Select,
          Dimensions: getSavePayloadBuilderDimensions(
            newState.updatedReportBuilderInfo.appliedDimensions,
            [
              ...newState.updatedReportBuilderInfo.selectedTemplate.ReportConfig
                .Builder[0].Select.Dimensions,
            ],
          ),
          Measures: [...getSavePayloadBuilderMeasures(
            newState.updatedReportBuilderInfo.appliedMeasures,
            template.ReportConfig.Builder[builderIdx].Select.Measures, // TODO: Find by namespace, not by index.
          ),

          ],
        },
        Filter: getSavePayloadBuilderFilters(
          addedFilter,
          newAppliedFilters,
          template.ReportConfig.Builder[builderIdx].Filter,
        ),
        Hierarchy: getReportHierarchy(appliedUserDimension, item.Hierarchy),
      });
    },
  );

  const dimensions = getSavePayloadDimensionColumns(
    newState.updatedReportBuilderInfo.appliedDimensions,
  );
  const measures = getSavePayloadMeasureColumns(
    newState.updatedReportBuilderInfo.appliedMeasures,
    getDefaultMeasures(defaultBuilderMeasures, template.ReportConfig.Columns),
    template.ReportConfig,
  );

  savePayloadReportConfig.Columns = [...dimensions, ...measures];
  savePayloadReportConfig.Sorting = newState?.updatedReportBuilderInfo?.sortState;
  savePayloadReportConfig.DimensionsMetaFields = getSavePayloadDimensionMetafields(savePayloadReportConfig.DimensionsMetaFields, newState.updatedReportBuilderInfo.appliedDimensions);

  savePayloadReportConfig.FiltersMetadata = getFilterMetaDataInfo(
    addedFilter,
    newAppliedFilters,
  );
  savePayloadReportConfig.Visualization = getUpdateVisualization(savePayloadReportConfig?.Visualization?.DisplayName,
    savePayloadReportConfig?.Visualization?.Type, savePayloadReportConfig?.Visualization?.Builder?.Properties, savePayloadReportConfig?.Visualization?.DrilldownConfigMapping, savePayloadReportConfig?.Visualization?.DrilldownPagination);

  return savePayloadReportConfig;
};

// Returns the filter meta data info based on the newly added filter.
// @addedFiltersConfig - Filter config of the newly added filter
// @appliedFilters - Filter response and data
export const getFilterMetaDataInfo = (
  addedFiltersConfig: IFilterConfig[],
  appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
) => {
  const userFilterId = addedFiltersConfig?.find(
    (config) => config.Type === FilterType.UserMultiSelect,
  )?.ID || '';
  if (userFilterId) {
    const DropdownValueType = appliedFilters[userFilterId].FilterResponse.Type;
    return {
      UserDropdownSelectType: DropdownValueType,
    };
  }
  return null;
};

// if the save fails, reset the filter to template value
// @savePayloadReportConfig - report builder save config
// @state - Report builder state
export const resetFilterOnFailure = (
  savePayloadReportConfig: IReportConfig,
  state: IReportBuilderState,
) => {
  // TODO: Augmented filter to be taken care
  const templateFilter = state.updatedReportBuilderInfo.selectedTemplate.ReportConfig.Builder[0]
    .Filter;
  // eslint-disable-next-line no-param-reassign
  savePayloadReportConfig.Builder = savePayloadReportConfig.Builder.map(
    (item: IBuilder) => ({
      ...item,
      Filter: templateFilter,
    }),
  );
  return savePayloadReportConfig;
};

// Return the updated State on additional Dimension success.
// @state: Report Builder State
// @payload: Additional dimension API response
export const getAppliedDimensionsOnSuccess = (
  state: IReportBuilderState,
  payload: IColumn[],
): IDimension[] => {
  const newState = state;
  let appliedDimensions = newState.updatedReportBuilderInfo.appliedDimensions;
  const dynamicDimensions = payload.map(
    (dim: IColumn) => ({
      Applied: DimensionStatus.NotApplied,
      Orderable: dim.Props?.Dimension?.Orderable,
      ZeroAnchor: dim.Props?.Dimension?.ZeroAnchor,
      DimensionMode: dim.Props?.Dimension?.DimensionMode,
      BuilderConfig: dim.BuilderConfig,
      Name: dim.Props?.ReferTo
        ? dim?.Name
        : dim?.BuilderConfig?.DisplayName || dim?.Name, // handling for dynamic meta fields by checking referToCol
      IsMasked: dim.Props.IsMasked,
      DimensionProp: dim.Props?.Dimension?.DimensionProp,
      Mode: dim.Props?.Mode,
      ReferTo: dim.Props?.ReferTo,
      ParentDimension: dim.Props?.ParentDimension,
      Props: dim.Props,
      IsUserDynamicField: dim?.Props?.IsUserDynamicField,
      IsFieldSwitch: dim?.Props?.IsFieldSwitch,
    } as IDimension),
  );
  appliedDimensions = reorderAppliedDimensions([...appliedDimensions, ...dynamicDimensions]);
  return appliedDimensions;
};

// Return the updated State on change grouping order.
// @state: Report Builder State
// @payload: Updated grouping info
export const getStateOnSetGrouping = (
  state: IReportBuilderState,
  payload: ISetGroupingPayload,
): IReportBuilderState => {
  let newState = state;
  const {
    sourceIndex, status, mode, destinationIndex, isSwapEnabled,
  } = payload;
  let updatedSource = sourceIndex;

  let newAppliedDimensions = newState.updatedReportBuilderInfo.appliedDimensions;
  const expressionStore = newState.updatedReportBuilderInfo.expressionStore;

  if (!newAppliedDimensions[updatedSource]?.BuilderConfig) {
    // For source we have to reorder the metafields in the list
    updatedSource = newAppliedDimensions.findIndex((dim, index) => index >= updatedSource && dim?.BuilderConfig);
  }

  let dimension = newAppliedDimensions[updatedSource]; // source dimension
  const desDimension = newAppliedDimensions[destinationIndex]; // destination dimension

  // if date-time field is added in column grouping
  if (
    status === DimensionStatus.Applied
    && mode === DimensionMode.ColumnGroup
    && dimension.DimensionProp?.Props.BinningType === DateBinningFormatters.NONE
  ) {
    dimension = {
      ...dimension,
      DimensionProp: {
        ...dimension.DimensionProp,
        Props: {
          ...dimension.DimensionProp.Props,
          BinningType: DateBinningFormatters.DATE_YEAR,
        },
      },
      Props: {
        ...dimension.Props,
        Dimension: {
          ...dimension.Props.Dimension,
          DimensionProp: {
            ...dimension.Props.Dimension.DimensionProp,
            Props: {
              ...dimension.Props.Dimension.DimensionProp.Props,
              BinningType: DateBinningFormatters.DATE_YEAR,
            },
          },
        },
      },
    };
  }

  // if swapping is there, then before applying changes to source dimension, we should swap its values in
  // destination dimension.
  if (isSwapEnabled) {
    // if destination dimension is mandatory applied , then it should remain mandatory applied only,
    // if swapped to row grouping
    let desDimStatus: DimensionStatus;
    if (desDimension.Applied === DimensionStatus.Mandatory) {
      desDimStatus = DimensionStatus.Mandatory;
      // and if source dimension is mandatory applied , then it should be applied
    } else if (dimension.Applied === DimensionStatus.Mandatory) {
      desDimStatus = DimensionStatus.Applied;
      // if both are mandatory or for any other case status should be swapped.
    } else {
      desDimStatus = dimension.Applied;
    }
    desDimension.Applied = desDimStatus;
    desDimension.DimensionMode = dimension.DimensionMode;
  }
  // making state changes to source dimension (applicable for all cases)
  dimension.Applied = status;
  dimension.DimensionMode = mode;

  // swapping source dimension with destination dimension in case of swapping true
  if (isSwapEnabled) {
    newAppliedDimensions[destinationIndex] = dimension;
    newAppliedDimensions[sourceIndex] = desDimension;
  }

  // change position of dimension to putBeforeIndex only in case of addition(no swapping)
  if (destinationIndex !== undefined && !isSwapEnabled) {
    newAppliedDimensions = newAppliedDimensions.filter((dim) => !areDimensionsEqual(dim, dimension));
    newAppliedDimensions.splice(destinationIndex, 0, dimension);
  }
  // At the time of deletion for custom Dimension the destination index will be zero
  if (destinationIndex === undefined && dimension.BuilderConfig.Entity === FieldEntitiesType.Custom && dimension.IsCustomExpression) {
    newAppliedDimensions = [...newAppliedDimensions.filter((dim: IDimension) => dim?.BuilderConfig?.Alias !== dimension?.BuilderConfig?.Alias)];
    delete expressionStore[dimension.BuilderConfig.uniqueKey];
  }

  newAppliedDimensions = reOrderDimensions(newAppliedDimensions);

  newState.updatedReportBuilderInfo.appliedDimensions = newAppliedDimensions;
  newState.updatedReportBuilderInfo.expressionStore = expressionStore;
  newState.updatedReportBuilderInfo.sortState = rebuildSortState(
    newState.updatedReportBuilderInfo.sortState,
    newState.updatedReportBuilderInfo.appliedDimensions,
    newState.updatedReportBuilderInfo.appliedMeasures,
    [],
    false,
  );
  if (dimension?.IsFieldSwitch && sourceIndex > destinationIndex && !isSwapEnabled) {
    newState = getStateOnSwitchUserFilter(newState, { index: destinationIndex, context: SwitchContext.Fields, isSwitchFieldAdded: true });
  }
  return newState;
};

// Return the updated State on dimension binning change.
// @state: Report Builder State
// @payload: Updated dimension info
export const getStateOnBinningChange = (
  state: IReportBuilderState,
  payload: ISetDimensionsBinningPayload,
): IReportBuilderState => {
  const newState = state;
  const { value, index } = payload;
  const newAppliedDimensions = newState.updatedReportBuilderInfo.appliedDimensions;
  const newAppliedDimension = newAppliedDimensions[index];
  newAppliedDimension.DimensionProp.Props.BinningType = value;
  newAppliedDimension.Props.Dimension.DimensionProp.Props.BinningType = value;
  newAppliedDimensions[index] = newAppliedDimension;
  newState.updatedReportBuilderInfo.appliedDimensions = newAppliedDimensions;
  return newState;
};

// Return the updated State on add measure.
// @state: Report Builder State
// @payload: Updated Measure Info
export const getStateOnAddMeasure = (
  state: IReportBuilderState,
  payload: IAddMeasurePayload,
): IReportBuilderState => {
  const { index, status } = payload;
  const {
    reportBuilderInfo: { additionalMeasures },
  } = state;
  if (index < 0 || index >= additionalMeasures.length) {
    return state;
  }

  const newMeasures = [...state.updatedReportBuilderInfo.appliedMeasures];
  const measure = additionalMeasures[index];

  const calculatedMeasuresList = state.updatedReportBuilderInfo.selectedTemplate.ReportConfig.CalculatedColumns;
  const isCalculated = fromPairs((calculatedMeasuresList || []).map((column) => [column?.Alias, true]));
  const isCalulatedMeasure = isCalculated[measure.Name];

  const updatedName = isMeasureDynamic(measure) && !isMeasureCustom(measure)
    ? validateAndAppendCountToName(measure, newMeasures)
    : measure.Name;

  const newMeasure = {
    ...measure,
    Name: updatedName,
    Props: {
      ...measure.Props,
      Mode: status,
      Measure: {
        ...measure.Props.Measure,
        Name: updatedName,
        JoinID: measure.Props.Measure.IsPreDefined ? measure.Props?.Measure?.JoinID || '' : measure?.BuilderConfig?.JoinID || '',
      },
      DrilldownEnabled: getIsDrilldownEnabledFlag(state, measure, isCalulatedMeasure),
    },
    BuilderConfig: {
      ...measure.BuilderConfig,
      DisplayName: updatedName,
    },
  };
  newMeasures.push(newMeasure);

  const newExprStore = updateExpressionStore(state, newMeasure, status);

  const drilldownConfigMapping = updateDrilldownConfigMapping(state, newMeasure, isCalulatedMeasure);
  const newState: IReportBuilderState = {
    ...state,
    updatedReportBuilderInfo: {
      ...state.updatedReportBuilderInfo,
      appliedMeasures: newMeasures,
      expressionStore: newExprStore,
      drilldownConfigInfo: {
        ...state.updatedReportBuilderInfo.drilldownConfigInfo,
        drilldownConfigMapping,
      },
    },
  };
  return getStateOnRebuildSortState(newState);
};
// updates drilldownConfigMapping to incorporate the newly added measure
// @state: Report Builder State
// @newMeasure: column
// @isCalulatedMeasure: boolean specifying if it is a calculatedColumn
const updateDrilldownConfigMapping = (state: IReportBuilderState, newMeasure: IColumn, isCalulatedMeasure:boolean) => {
  const drilldownConfigMapping = state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping;
  const builderMeasuresList = builderMeasuresListSelector({ reportBuilder: state } as IGlobalState);
  let expr = newMeasure?.Props?.Measure?.Expression;
  if (!expr) {
    const currentMeasure = builderMeasuresList.find((builderMeasure: IBuilderMeasure) => builderMeasure.Alias === newMeasure.Name);
    expr = currentMeasure?.MeasureExpression;
  }
  const isDrilldownDisabled = isCalulatedMeasure || !isDrilldownPossible(expr);
  if (isDrilldownDisabled) {
    return drilldownConfigMapping;
  }

  const defaultEntity = defaultEntitySelector({ reportBuilder: state } as IGlobalState);
  return groupByJoinIdAndEntity(
    state?.reportBuilderInfo.fields,
    newMeasure,
    state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping,
    state?.updatedReportBuilderInfo?.appliedMeasures,
    defaultEntity,
  );
};
// computes the DrilldownEnabled flag for the specified measure
// @state: Report Builder State
// @measure: column
// @isCalulatedMeasure: boolean specifying if it is a calculated column
const getIsDrilldownEnabledFlag = (state: IReportBuilderState, measure: IColumn, isCalulatedMeasure: boolean) => {
  if (isCalulatedMeasure || !state?.updatedReportBuilderInfo?.drilldownConfigInfo?.enableDrilldown) {
    return false;
  }
  if (measure?.Props?.Measure?.IsPreDefined) {
    return measure?.Props.AllowDrillDown;
  }
  return isDrilldownPossible(measure?.Props?.Measure?.Expression);
};
// updates the expression store with status property for the specified measure
// @state: Report Builder State
// @measure: column
// status: ColumnMode
const updateExpressionStore = (state: IReportBuilderState, measure: IColumn, status:ColumnModes) => {
  const newExprStore = { ...state.updatedReportBuilderInfo.expressionStore };
  if (isMeasureCustom(measure) && newExprStore[measure.BuilderConfig?.uniqueKey]) {
    newExprStore[measure.BuilderConfig.uniqueKey] = {
      ...newExprStore[measure.BuilderConfig.uniqueKey],
      Mode: status,
    };
  }
  return newExprStore;
};
// Return the updated State on removing measure.
// @state: Report Builder State
// @payload: Updated Measure Info
export const getStateOnRemoveMeasure = (
  state: IReportBuilderState,
  payload: IRemoveMeasurePayload,
): IReportBuilderState => {
  const { index } = payload;

  const newMeasures = [...state.updatedReportBuilderInfo.appliedMeasures];
  if (index < 0 || index >= newMeasures.length) return state;

  const remMeasure = newMeasures.splice(index, 1)[0];
  const newExprStore = { ...state.updatedReportBuilderInfo.expressionStore };
  let remMeasureEntity = remMeasure.Props.Measure?.Entity;
  let remMeasureJoinId = remMeasure.Props.Measure?.JoinID;
  if (isMeasureCustom(remMeasure) && newExprStore[remMeasure.BuilderConfig?.uniqueKey]) {
    delete newExprStore[remMeasure.BuilderConfig.uniqueKey];
    const defaultEntity = defaultEntitySelector({ reportBuilder: state } as IGlobalState);
    const fieldsData = fieldSelectors({ reportBuilder: state } as IGlobalState);
    const { Entity, JoinId } = getEntityJoinIdForCustomMeasure(fieldsData, remMeasure, defaultEntity);
    remMeasureEntity = Entity;
    remMeasureJoinId = JoinId;
  }

  const targetMeasure = { name: remMeasure?.Name, joinId: remMeasureJoinId || '', entity: remMeasureEntity };
  const { newConfigMapping, newConfigColumns } = removeMeasureFromDrilldownConfigMapping(state.updatedReportBuilderInfo.drilldownConfigInfo, targetMeasure);

  const newState: IReportBuilderState = {
    ...state,
    updatedReportBuilderInfo: {
      ...state.updatedReportBuilderInfo,
      appliedMeasures: newMeasures,
      expressionStore: newExprStore,
      drilldownConfigInfo: {
        ...state.updatedReportBuilderInfo.drilldownConfigInfo,
        drilldownConfigMapping: newConfigMapping,
        drilldownColumnConfig: newConfigColumns,
      },
    },
  };
  return getStateOnRebuildSortState(newState);
};

// Return the updated State on measure change
// @state: Report Builder State
// @payload: Updated Measure Info
export const getStateOnMoveMeasure = (
  state: IReportBuilderState,
  payload: IMoveMeasurePayload,
): IReportBuilderState => {
  const { sourceIndex, destinationIndex } = payload;
  const newMeasures = [...state.updatedReportBuilderInfo.appliedMeasures];
  if (
    sourceIndex < 0
    || destinationIndex < 0
    || sourceIndex >= newMeasures.length
    || destinationIndex >= newMeasures.length
  ) {
    return state;
  }

  const removed = newMeasures.splice(sourceIndex, 1);
  if (removed.length === 0) return state;
  newMeasures.splice(destinationIndex, 0, removed[0]);
  return {
    ...state,
    updatedReportBuilderInfo: {
      ...state.updatedReportBuilderInfo,
      appliedMeasures: newMeasures,
    },
  };
};

// Return the updated State on measure change
// @state: Report Builder State
// @payload: Updated Measure Info
export const getStateOnUpdateMeasure = (
  state: IReportBuilderState,
  payload: ISetMeasurePayload,
): IReportBuilderState => {
  const { index, newMeasure } = payload;
  const newMeasures = [...state.updatedReportBuilderInfo.appliedMeasures];
  const oldMeasure = { ...newMeasures[index] };
  newMeasures[index] = { ...newMeasure };

  const sortState = state.updatedReportBuilderInfo.sortState.map(
    (sort): ISorting => {
      if (sort.Field === oldMeasure.Name) {
        return { ...sort, Field: newMeasure.Name };
      }
      return { ...sort };
    },
  );
  const drilldownConfigMapping = cloneDeep(state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping || {});
  Object.keys(drilldownConfigMapping)?.forEach((key: string) => {
    const measureIndex = drilldownConfigMapping?.[key]?.findIndex((measure) => measure?.name === oldMeasure?.Name);
    if (measureIndex > -1) {
      drilldownConfigMapping[key][measureIndex].name = newMeasure.Name;
    }
  }, {});

  return {
    ...state,
    updatedReportBuilderInfo: {
      ...state.updatedReportBuilderInfo,
      appliedMeasures: newMeasures,
      drilldownConfigInfo: {
        ...state.updatedReportBuilderInfo.drilldownConfigInfo,
        drilldownConfigMapping,
      },
      sortState,
    },
  };
};
// Get the updated Sort State
// @state - Report Builder State
// @noMultiSort - boolean to check the multiSort
export const getStateOnRebuildSortState = (
  state: IReportBuilderState,
  noMultiSort?: boolean,
): IReportBuilderState => {
  const {
    updatedReportBuilderInfo: { appliedDimensions, appliedMeasures, sortState },
  } = state;
  return {
    ...state,
    updatedReportBuilderInfo: {
      ...state.updatedReportBuilderInfo,
      sortState: rebuildSortState(
        sortState,
        appliedDimensions,
        appliedMeasures,
        [],
        noMultiSort,
      ),
    },
  };
};

// Update the filter config based on the is partition condition
// @filterConfig - Filter config
// @appliedFilters - Filter response and data
// @partitionColumnFilterDateLimit - This is part of the setting
export const getUpdatedAppliedFiltersForPartitionColumn = (
  filterConfig: IFilterConfig[],
  appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
  partitionColumnFilterDateLimit: number,
) => {
  const newAppliedFilters = appliedFilters;
  filterConfig.forEach((filter) => {
    if (filter.IsPartitionColumn && !filter?.IsRemoved) {
      const filterID = filter.ID;
      const filterResponse = newAppliedFilters[filterID]?.FilterResponse;
      if (filterResponse?.Type === IDateRangeTypes.Relative) {
        newAppliedFilters[filterID].FilterResponse = getDatesIfRelativeRangeIsRestricted(
          filterResponse?.Value,
          partitionColumnFilterDateLimit,
        );
      } else {
        newAppliedFilters[filterID].FilterResponse = getDatesIfAbsoluteRangeIsRestricted(
          moment(filterResponse?.Value?.From),
          moment(filterResponse?.Value?.To),
          partitionColumnFilterDateLimit,
        );
      }
    }
  });
};

// Validation check for the string field applied
// @appliedDimensions: Array which store all the applied Dimnsions
export const isStringFieldApplied = (appliedDimensions: IDimension[]) => appliedDimensions?.find(
  (dim) => dim.DimensionProp?.DataType === ColumnDataTypes.String
      && dim?.Applied === DimensionStatus.Applied,
);
// Validation check for the duplicate input.
// @inputString - input value.
// @comparisonData - Array of object from which need to check the duplicate value
// @propertyToCheck - Duplicate Value Key
export const getIsDuplicateInput = (inputString:string, comparisonData :Array<Object>, propertyToCheck:any) => {
  const lowerCaseInput = inputString.toLowerCase().trim();
  const filteredArray = comparisonData.filter((obj:any) => obj[propertyToCheck].toLowerCase().trim() === lowerCaseInput);
  return filteredArray.length > 0;
};

// Return the Updated Alias with Entity and SchemaName
export const getUpdatedAlias = (
  NameSpaceType: string,
  SchemaName: string,
  Entity: string,
) => {
  if (NameSpaceType !== Entity) {
    return `${Entity}${SchemaName}`;
  }
  return SchemaName;
};

// Combine renderReportConfig and RawReportConfig and returns the updated Config.
// @rawReportConfig: contains the rawReportConfig which is stored in redux store.
// @renderReportConfig: contains the renderReportConfig which is stored in redux store.
// Returns combined report config of IReportConfigType
export const getCombinedReportConfig = (rawReportConfig: IReportConfig, renderReportConfig: ReportResponseModel.IReportConfig): IReportConfig => {
  const updatedConfig = rawReportConfig;

  updatedConfig.Columns = renderReportConfig.Columns;
  updatedConfig.FiltersMetadata = renderReportConfig.FiltersMetadata;
  const updatedVisualization = getInitialVisualization(updatedConfig?.Visualization, false);
  updatedConfig.Visualization = {
    Type: updatedVisualization.type,
    Builder: { ...rawReportConfig.Visualization.Builder, Properties: updatedVisualization.attributes },
  };

  updatedConfig.Columns = isValidArray(updatedConfig?.Columns) && updatedConfig?.Columns?.map((column:IColumn) => {
    if (column?.Props?.Measure?.IsCustomExpression) {
      const customMeasureBuilder = isValidArrayAndNotEmpty(updatedConfig.Builder) && updatedConfig.Builder[0].Select.Measures.find((measure) => measure?.Alias === column?.Name);
      column = {
        ...column,
        BuilderConfig: {
          ...column.BuilderConfig,
          Alias: column?.Name,
          Entity: FieldEntitiesType.Custom,
          uniqueKey: uuidv4(),
        },
        Props: {
          ...column?.Props,
          Measure: {
            ...column?.Props?.Measure,
            Expression: customMeasureBuilder.MeasureExpression,
          },
        },
      };
    }
    if (column?.Props?.Dimension?.IsCustomExpression) {
      column = {
        ...column,
        BuilderConfig: {
          ...column.BuilderConfig,
          uniqueKey: uuidv4(),
        },
      };
    }
    return column;
  });
  return updatedConfig;
};

// Build Custom Expression from the report Config and update in the expression Store.
// @reportConfig: contains the rawReportConfig which is stored in redux store.
// @expressionStore: contains all the custom expressions as key value pair object which is stored in redux store.
// Returns updated Expression Store of type ObjGeneric<ICustomExpression>;
export const getExpressionStoreFromConfig = (reportConfig: IReportConfig, expressionStore: ObjGeneric<ICustomExpression>) : ObjGeneric<ICustomExpression> => {
  let updatedExpressionStore = { ...expressionStore };
  const columns = reportConfig?.Columns;

  if (isValidArrayAndNotEmpty(columns)) {
    columns?.forEach((column) => {
      let expression: ICustomExpression;
      if (isMeasureCustom(column)) {
        expression = {
          displayName: column.Name,
          jsonExpression: column?.Props?.Measure?.Expression,
          Mode: column?.Props?.Mode,
          uniqueId: column?.BuilderConfig?.uniqueKey,
          Formatter: column?.Props?.Formatter,
          DataType: getDataTypeFromCustomColumn(column?.Props?.Measure?.Expression),
          ExpressionType: ExpressionContext.Measure,
        };
      } else if (isDimensionCustom(column)) {
        expression = {
          displayName: column.Name,
          jsonExpression: column?.Props?.Dimension?.Expression,
          Mode: column?.Props?.Mode,
          uniqueId: column?.BuilderConfig?.uniqueKey,
          Formatter: column?.Props?.Formatter,
          DataType: getDataTypeFromCustomColumn(column?.Props?.Dimension?.Expression),
          ExpressionType: ExpressionContext.Dimension,
        };
      }
      if (expression) {
        updatedExpressionStore = {
          ...updatedExpressionStore,
          ...{
            [column?.BuilderConfig?.uniqueKey]: expression,
          },
        };
      }
    });
  }

  return updatedExpressionStore;
};

// Updates isDynamic flag so the same additonal filter can be removed even after stored as a default filter in report config via report builder or save/saveAs.
// @filterConfig: contains the filterConfig which is stored in redux store.
// Returns updated filter Config of Type IFilterConfig[]
export const getUpdatedReportFilterConfig = (filterConfig: IFilterConfig[]): IFilterConfig[] => {
  const updatedFilterConfig = filterConfig;

  return isValidArrayAndNotEmpty(updatedFilterConfig) ? updatedFilterConfig.map((filter: IFilterConfig) => {
    filter.IsDynamic = filter.IsDynamic || filter.IsAdditional;
    return filter;
  }) : updatedFilterConfig;
};

// Reorder the Dimensions array set all the metafields at the end of the array
// @appliedDimensions: contains all the dimensions stored in redux store
// Returns the reordered array.
export const reorderAppliedDimensions = (appliedDimensions: IDimension[]): IDimension[] => {
  const metafields: IDimension[] = [];
  const dimensions: IDimension[] = isValidArrayAndNotEmpty(appliedDimensions) && appliedDimensions.filter((dimension:IDimension) => {
    if (dimension?.Props?.ParentDimension) {
      metafields.push(dimension);
    }
    return !dimension?.Props?.ParentDimension;
  });

  return [...dimensions, ...metafields];
};

// Returns the updated report builder state on add dynamic filter
// @state: report builder state
// @index: index of newely added dynamic filter
export const getStateOnAddDynamicFilter = (state:IReportBuilderState, index:number) => {
  const newState = state;
  const { Title, Description } = newState.updatedReportBuilderInfo.reportBuilderSavePayload.Details;
  let newFilterConfig = newState.filterBuilder.filterConfig;
  newState.assortedInfo.isFormDisabled = getIsReportBuilderSaveFormDisable(Title, Description, state)
        || getIsFilterFormDisable(newState.filterBuilder);
  const newAppliedFilters = newState.filterBuilder.appliedFilters;

  const dynamicFilter = isValidArrayAndNotEmpty(newState?.reportBuilderInfo?.additionalFilters) && newState.reportBuilderInfo.additionalFilters[index];
  const userMultiSelectFilterData = isValidArrayAndNotEmpty(newFilterConfig) && newFilterConfig.find(
    (config: IFilterConfig) => config.Type === FilterType.UserMultiSelect,
  );
  newFilterConfig = isValidArrayAndNotEmpty(newFilterConfig) && newFilterConfig.filter(
    (config: IFilterConfig) => config.ID !== dynamicFilter?.ID,
  );
  newFilterConfig.push(dynamicFilter);
  newAppliedFilters[dynamicFilter.ID] = new FilterFetchUtility(
    null,
  ).GetDefaultSingleFilter(dynamicFilter, null);
  if (
    dynamicFilter?.Entity === FieldEntitiesType.User
        && userMultiSelectFilterData
  ) {
    newState.filterBuilder.isUsersModified = true;
    newAppliedFilters[userMultiSelectFilterData.ID] = new FilterFetchUtility(null).GetDefaultSingleFilter(
      userMultiSelectFilterData,
      null,
    );
  }
  newState.filterBuilder.filterConfig = newFilterConfig;
  newState.filterBuilder.appliedFilters = newAppliedFilters;

  return newState;
};

// Returns the drilldown config object for measures with name and joinId
// @newMeasure: when new measure gets added
export const getDrilldownMapping = (MeasureList: IColumn[], state: IReportBuilderState): DrilldownConfigMapping => {
  const updatedMeasuresList = isValidArrayAndNotEmpty(MeasureList) ? MeasureList.filter((measure: IColumn) => (Object.prototype.hasOwnProperty.call(measure?.Props, 'DrilldownEnabled')
    ? measure?.Props?.DrilldownEnabled : measure?.Props?.AllowDrillDown)) : [];

  const defaultEntity = defaultEntitySelector({ reportBuilder: state } as IGlobalState);
  const fieldsData = fieldSelectors({ reportBuilder: state } as IGlobalState);

  const modifiedMeasuresList = cloneDeep(updatedMeasuresList).map((measure: IColumn) => {
    if (measure.Props.Measure.IsCustomExpression) {
      const { Entity, JoinId } = getEntityJoinIdForCustomMeasure(fieldsData || [], measure, defaultEntity);
      measure.Props.Measure.Entity = Entity;
      measure.Props.Measure.JoinID = JoinId;
    }
    return measure;
  });
  const groupedByJoinId = groupBy(modifiedMeasuresList, 'Props.Measure.JoinId');
  const groupedByEntity:any = mapValues(groupedByJoinId, (items: IColumn) => groupBy(items, 'Props.Measure.Entity'));
  const drilldownConfigMapping:DrilldownConfigMapping = {};
  Object.keys(groupedByEntity || {})?.forEach((key: string) => {
    Object.keys(groupedByEntity[key])?.forEach((entityKey: string) => {
      const id = uuidv4();
      drilldownConfigMapping[id] = (groupedByEntity[key][entityKey])?.map((val: IColumn) => ({ name: val?.Name, joinId: val?.Props?.Measure?.JoinID || '', entity: val?.Props?.Measure?.Entity || '' }));
    });
  });
  return drilldownConfigMapping;
};

// Returns the drilldown config object grouped by name and joinId for UI purpose
// @newMeasure: when new measure gets added
// @drilldownConfigMapping: existing mapping
// @appliedMeasures: existing applied measures

export const groupByJoinIdAndEntity = (fieldsData:Array<IField>, newMeasure: IColumn, drilldownConfigMapping: DrilldownConfigMapping, appliedMeasures: IColumn[], defaultEntity: string): DrilldownConfigMapping => {
  const mapping = cloneDeep(drilldownConfigMapping || {});

  let currentMeasureEntity = newMeasure?.Props?.Measure?.Entity;
  let currentMeasureJoinId = newMeasure?.Props?.Measure?.JoinID || '';

  if (newMeasure?.Props?.Measure?.IsCustomExpression) {
    const { Entity, JoinId } = getEntityJoinIdForCustomMeasure(fieldsData, newMeasure, defaultEntity);
    currentMeasureEntity = Entity;
    currentMeasureJoinId = JoinId;
  }

  let isMeasureAdded = false;
  Object.keys(drilldownConfigMapping || {})?.forEach((key) => {
    const existJoinId = drilldownConfigMapping?.[key]?.[0]?.joinId || '';
    const existingMeasure = appliedMeasures?.find((measure: IColumn) => measure?.Name === drilldownConfigMapping[key][0]?.name);

    let existingMeasureEntity = existingMeasure?.Props?.Measure?.Entity;

    if (existingMeasure?.Props?.Measure?.IsCustomExpression) {
      const { Entity } = getEntityJoinIdForCustomMeasure(fieldsData, existingMeasure, defaultEntity);
      existingMeasureEntity = Entity;
    }

    if (currentMeasureJoinId === existJoinId && currentMeasureEntity === existingMeasureEntity) {
      mapping[key].push({ name: newMeasure?.Name, joinId: currentMeasureJoinId, entity: currentMeasureEntity });
      isMeasureAdded = true;
    }
  });
  if (!isMeasureAdded) {
    const id = uuidv4();
    mapping[id] = [{ name: newMeasure?.Name, joinId: currentMeasureJoinId, entity: currentMeasureEntity }];
  }
  return mapping;
};

// Returns the Initial column object
// @DefaultDrilldownResponse: default columns response from api
// @drilldownConfigMapping: existing mapping
// @drilldownConfigInfo: the config of drilldown which contains measure and column info

export const getInitialDrilldownConfigInfo = (DefaultDrilldownResponse: DrilldownColumnConfigResponse, drilldownConfigInfo: IdrilldownConfigInfo): DrilldownColumnConfig => {
  const drilldownConfig = {} as DrilldownColumnConfig;
  if (isValidObjectAndNotEmpty(drilldownConfigInfo.drilldownConfigMapping)) {
    Object.keys(DefaultDrilldownResponse || {})?.forEach((accordId: string) => {
      if (!drilldownConfigInfo?.drilldownColumnConfig?.[accordId]?.defaultColumns?.length) {
        const sortState = isValidArrayAndNotEmpty(DefaultDrilldownResponse?.[accordId]?.sortState) ? DefaultDrilldownResponse?.[accordId]?.sortState[0] : null;
        const sortColumn = DefaultDrilldownResponse[accordId]?.defaultColumns?.find((item:IColumn) => sortState?.Field === item.BuilderConfig.Alias);
        const initialSort = {
          Field: sortColumn?.Name,
          Direction: sortState?.Direction,
        };
        drilldownConfig[accordId] = {
          defaultColumns: DefaultDrilldownResponse[accordId]?.defaultColumns?.filter((dim:IColumn) => dim?.Props?.Mode !== ColumnModes.Hidden).map((column: IColumn) => ({
            ...column,
            Props: {
              ...column.Props,
              Dimension: {
                ...column.Props.Dimension,
                Applied: DimensionStatus.Applied,
              },
            },
          })) || [],
          sortState: [initialSort],
          additionalDrilldownColumns: [],
          isAdditionalColumnFetched: false,
        };
      }
    });
  }

  return drilldownConfig;
};

// Returns the updated column object when any param is updated
// @colObj: contains column info to be updated
// @drilldownColumnConfigInfo: column info of selected accordian

export const getUpdatedDrilldownColConfig = (state: IReportBuilderState, colObj: DrilldownFieldUpdationsAction):DrillDownColumnConfigObj => {
  const { updatedReportBuilderInfo: reportBuilder } = state;
  const drilldownColumnConfigInfo = state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig;
  const curDrillDownConfig = cloneDeep(drilldownColumnConfigInfo[colObj?.groupingId]);

  const updatedName = colObj.column.BuilderConfig.IsDynamicField ? getUpdatedDrilldownColsName(colObj.column.BuilderConfig.DisplayName, curDrillDownConfig.defaultColumns) : getUpdatedDrilldownColsName(colObj.column.Name, curDrillDownConfig.defaultColumns);
  const updatedCol: DrilldownFieldUpdationsAction = {
    ...colObj,
    column: {
      ...colObj.column,
      Name: colObj.type === DrilldownFieldUpdationType.ADD ? updatedName : colObj.column.Name,
      Props: {
        ...colObj.column.Props,
        Dimension: {
          ...colObj.column.Props.Dimension,
          Applied: colObj.type === DrilldownFieldUpdationType.ADD ? DimensionStatus.Applied : DimensionStatus.NotApplied,
        },
      },
      BuilderConfig: {
        ...colObj.column?.BuilderConfig,
        DisplayName: colObj.column.BuilderConfig.IsDynamicField ? updatedName : colObj.column.BuilderConfig.DisplayName,
        IsDynamicField: colObj.type === DrilldownFieldUpdationType.ADD ? false : colObj.column.BuilderConfig.IsDynamicField,
      },
    },

  };
  if (colObj.type === DrilldownFieldUpdationType.REMOVE) {
    curDrillDownConfig.defaultColumns = isValidArrayAndNotEmpty(curDrillDownConfig?.defaultColumns) ? curDrillDownConfig.defaultColumns?.filter((col: IColumn) => getUniqIdentiferForDrilldownCols(col) !== getUniqIdentiferForDrilldownCols(colObj?.column)) : [];

    const updatedAddtCols = curDrillDownConfig.additionalDrilldownColumns.map((item: IColumn) => {
      if (getUniqIdentiferForDrilldownCols(item) === getUniqIdentiferForDrilldownCols(updatedCol.column)) {
        return {
          ...item,
          Props: {
            ...item.Props,
            Dimension: {
              ...item.Props.Dimension,
              Applied: DimensionStatus.NotApplied,
            },
          },
        };
      }
      return item;
    });
    curDrillDownConfig.additionalDrilldownColumns = updatedAddtCols;
  } else {
    curDrillDownConfig?.defaultColumns?.push(updatedCol.column);

    const updatedAddtCols = curDrillDownConfig.additionalDrilldownColumns?.map((item: IColumn) => {
      if (getUniqIdentiferForDrilldownCols(item) === getUniqIdentiferForDrilldownCols(updatedCol.column)) {
        return {
          ...item,
          Props: {
            ...item.Props,
            Dimension: {
              ...item.Props.Dimension,
              Applied: DimensionStatus.Applied,
            },
          },
        };
      }
      return item;
    });
    curDrillDownConfig.additionalDrilldownColumns = updatedAddtCols;
  }

  curDrillDownConfig.sortState = rebuildSortState(curDrillDownConfig.sortState, reportBuilder.appliedDimensions, reportBuilder.appliedMeasures, curDrillDownConfig.defaultColumns, false, true);
  return curDrillDownConfig;
};

// Returns the updated column object when any name or something updated
// @drilldownConfig: config of drilldown with measures and columns
// @payload: contains index, key is current accordian key and newMeasure is updated column
export const getStateOnUpdateDrilldownColumn = (
  drilldownConfig: IdrilldownConfigInfo,
  payload: {
    index: number;
    newMeasure: IColumn;
    key: string;
  },
): IdrilldownConfigInfo => {
  const { index, newMeasure, key } = payload;
  const newColumn = [...drilldownConfig.drilldownColumnConfig[key]?.defaultColumns];
  const oldColumn = { ...newColumn[index] };
  newColumn[index] = { ...newMeasure };

  const sortState = drilldownConfig.drilldownColumnConfig[key]?.sortState?.map(
    (sort: IDrilldownSorting): ISorting => {
      if (sort?.Field === oldColumn?.Name) {
        return { ...sort, Field: newMeasure.Name };
      }
      return { ...sort };
    },
  );
  drilldownConfig.drilldownColumnConfig[key].defaultColumns = newColumn;
  drilldownConfig.drilldownColumnConfig[key].sortState = sortState;
  return drilldownConfig;
};

// Returns the updated column when user reorder the columns
// @drilldownColumnConfig: config of drilldown with measures and columns
// @payload: contains index, key is current accordian key and newMeasure is updated column
export const getStateOnMoveColumns = (
  drilldownColumnConfig: DrilldownColumnConfig,
  payload: {
    startElement:IColumn;
    endElement:IColumn;
    key: string;
  },
): IColumn[] => {
  const { startElement, endElement, key } = payload;
  const newColumns = cloneDeep(drilldownColumnConfig[key]?.defaultColumns);
  const sourceIndex = newColumns?.findIndex((col: IColumn) => getUniqIdentiferForDrilldownCols(col) === getUniqIdentiferForDrilldownCols(startElement));
  const destinationIndex = newColumns?.findIndex((col: IColumn) => getUniqIdentiferForDrilldownCols(col) === getUniqIdentiferForDrilldownCols(endElement));

  if (
    sourceIndex < 0
    || destinationIndex < 0
    || sourceIndex >= newColumns.length
    || destinationIndex >= newColumns.length
  ) {
    return newColumns;
  }

  const removed = newColumns.splice(sourceIndex, 1);
  if (removed.length === 0) return newColumns;
  newColumns.splice(destinationIndex, 0, removed[0]);
  return newColumns;
};

// Returns the updated name when dupliction scenario occures,
// @name: name of current column
// @allDefaultCols: all default columns
export const getUpdatedDrilldownColsName = (name: string, allDefaultCols: Array<IColumn>): string => {
  let updatedName = name || '';
  const originalName = updatedName.split('_')[0];
  const allNames = allDefaultCols.map((item:IColumn) => item.Name);
  let counter = 1;
  while (allNames.includes(updatedName)) {
    updatedName = `${originalName}_${counter}`;
    counter += 1;
  }
  return updatedName;
};

// Returns the sort object for current accordian columns
// @state: current state
// @payload: contains current accordian key, multisort and columnname info
export const sortDrilldownColumns = (state: IReportBuilderState, payload: ISortDrilldownColumnPayload): ISorting[] => {
  const { updatedReportBuilderInfo: reportBuilder } = state;
  const {
    key, multiSort, columnName, order,
  } = payload;
  const drilldownSortState = reportBuilder?.drilldownConfigInfo?.drilldownColumnConfig[key]?.sortState;
  const drilldownColumns = reportBuilder?.drilldownConfigInfo?.drilldownColumnConfig[key]?.defaultColumns;
  const [sortState, hasChanged] = sortColumns(
    drilldownSortState,
    reportBuilder.appliedDimensions,
    reportBuilder.appliedMeasures,
    draggableItemType.DrilldownDim,
    columnName,
    order,
    multiSort,
    drilldownColumns,
    true,
  );
  if (!hasChanged) return drilldownSortState;
  state.updatedReportBuilderInfo.sortState = sortState;

  return sortState;
};

export const removeMeasureFromDrilldownConfigMapping = (
  config:IdrilldownConfigInfo,
  target:MeasureListWithJoinId,
) => {
  const newConfigMapping = cloneDeep(config.drilldownConfigMapping);
  const newConfigColumns = cloneDeep(config.drilldownColumnConfig);
  Object.keys(newConfigMapping).forEach((key) => {
    const index = newConfigMapping[key].findIndex(
      (item) => item.name === target.name && item.joinId === target.joinId,
    );

    if (index !== -1) {
      newConfigMapping[key].splice(index, 1);

      if (newConfigMapping[key].length === 0) {
        delete newConfigMapping[key];
        delete newConfigColumns[key];
      }
    }
  });
  return { newConfigMapping, newConfigColumns };
};

export const getUpdatedDrilldownCols = (drilldownColumnConfig:DrilldownColumnConfig) => {
  const updatedData = Object.keys(drilldownColumnConfig).reduce((acc: any, key: any) => {
    const currentObject = drilldownColumnConfig[key];
    const { defaultColumns, sortState } = currentObject;
    const filteredColumns = defaultColumns?.filter((column: IColumn) => column.Props.Dimension.Applied !== 0)?.map((column: IColumn) => ({
      ...column,
      BuilderConfig: {
        ...column.BuilderConfig,
        IsDynamicField: false,
      },
    }));

    acc[key] = {
      sortState,
      defaultColumns: filteredColumns,
    };
    return acc;
  }, {});
  return updatedData;
};

export const getUpdatedFetchdrilldownColumnsRes = (drilldownColumnConfigResponse: DrilldownColumnConfig,
  appliedMeasures: IColumn[], config: IdrilldownConfigInfo, calculatedMeasuresList: IBuilderMeasure[], state:IReportBuilderState) => {
  const drilldownColumns = {} as DrilldownColumnConfig;
  const newConfigMapping = cloneDeep(config.drilldownConfigMapping);

  const measureNames = appliedMeasures?.map((measure:IColumn) => measure?.Name) || [];

  // Filter drilldownConfigMapping to remove unwanted measures
  const filteredDrilldownConfigMapping = Object.keys(newConfigMapping).reduce((acc:DrilldownConfigMapping, key) => {
    const filteredMeasures = newConfigMapping[key].filter((measure:MeasureListWithJoinId) => measureNames.includes(measure.name));

    if (filteredMeasures.length > 0) {
      acc[key] = filteredMeasures;
    }
    return acc;
  }, {});

  // adding measures to the drilldownConfig which were added in report page
  const doesDrilldownConfigExist = (measureName: string) => Object.values(filteredDrilldownConfigMapping).some((configList) => configList.some(({ name }) => name === measureName));

  const isCalculated = fromPairs((calculatedMeasuresList || []).map((column) => [column?.Alias, true]));

  const measuresWithoutDrilldownConfig = [] as IColumn[];
  const builderMeasuresList = builderMeasuresListSelector({ reportBuilder: state } as IGlobalState);

  appliedMeasures.forEach((measure: IColumn) => {
    let expr = measure?.Props?.Measure?.Expression;
    if (!expr) {
      const selectedMeasure = builderMeasuresList.find((builderMeasure: IBuilderMeasure) => builderMeasure.Alias === measure.Name);
      expr = selectedMeasure?.MeasureExpression;
    }
    if (!doesDrilldownConfigExist(measure.Name) && !isCalculated[measure.Name] && isDrilldownPossible(expr)) {
      measuresWithoutDrilldownConfig.push(measure);
    }
  });

  const updatedMeasuresList = measuresWithoutDrilldownConfig?.map((measure: IColumn) => setDrilldownEnableFlag(measure)) || [];

  const additionalMapperInfo = getDrilldownMapping(updatedMeasuresList || [], state);

  // removing unwanted keys from drilldownConfig which are removed from drilldownMapper
  const accordIds = Object.keys(drilldownColumnConfigResponse || {}) || [];
  const mapperAccordIds = Object.keys(newConfigMapping || {}) || [];
  const updatedAccordIds = accordIds.filter((item:string) => mapperAccordIds.includes(item));

  updatedAccordIds.forEach((key: string) => {
    const defaultCols = drilldownColumnConfigResponse[key]?.defaultColumns || [];
    const updatedCols = defaultCols.map((measure: IColumn) => ({
      ...measure,
      Props: {
        ...measure.Props,
        Dimension: {
          ...measure.Props.Dimension,
          Applied: DimensionStatus.Applied,
        },
      },
    }));
    drilldownColumns[key] = {
      ...drilldownColumnConfigResponse[key],
      defaultColumns: updatedCols,
      additionalDrilldownColumns: [],
      isAdditionalColumnFetched: false,
    };
  });
  const configMapping = { ...filteredDrilldownConfigMapping, ...additionalMapperInfo };

  return { configMapping, drilldownColumns };
};

// Returns the updated drilldown mapper when custom expression is edited,
// @originalMeasure:current measure
// @updatedMeasure:new measure
// @config:drilldownConfigInfo
// @defaultEntity:default entity for the report
// @fieldsData:fields data api response
export const handleCustExpUpdateForDrilldownMapper = (
  originalMeasure:IColumn,
  updatedMeasure:IColumn,
  config: IdrilldownConfigInfo,
  defaultEntity: string,
  fieldsData: Array<IField>,
) => {
  let newConfigMapping = cloneDeep(config.drilldownConfigMapping);
  const newConfigColumns = cloneDeep(config.drilldownColumnConfig);

  const originalCol = getEntityJoinIdForCustomMeasure(fieldsData, originalMeasure, defaultEntity);
  const originalColMeasure = { joinId: originalCol.JoinId, entity: originalCol.Entity };

  const newCol = getEntityJoinIdForCustomMeasure(fieldsData, updatedMeasure, defaultEntity);
  const newColMeasure = { joinId: newCol.JoinId, entity: newCol.Entity };

  const isEntityOrJoinIdModified = !isEqual(originalColMeasure, newColMeasure);
  const isNameModified = originalMeasure.Name !== updatedMeasure.Name;

  const isOnlyNameModified = isNameModified && !isEntityOrJoinIdModified;
  const changeDetected = isEntityOrJoinIdModified || isNameModified;

  const isDrilldownAllowed = isDrilldownPossible(updatedMeasure.Props.Measure?.Expression);

  if (!changeDetected) {
    return { newConfigMapping, newConfigColumns };
  }
  Object.keys(newConfigMapping).forEach((key) => {
    const index = newConfigMapping[key]?.findIndex(
      (item) => item.name === originalMeasure.Name && item.joinId === originalColMeasure.joinId,
    );
    if (index !== -1) {
      if (isOnlyNameModified) {
        newConfigMapping[key][index] = { ...newConfigMapping[key][index], name: updatedMeasure.Name };
      } else {
        newConfigMapping[key].splice(index, 1);
        if (newConfigMapping[key].length === 0) {
          delete newConfigMapping[key];
          delete newConfigColumns[key];
        }
      }
    }
  });

  if (isDrilldownAllowed && isEntityOrJoinIdModified) {
    const id = uuidv4();
    newConfigMapping = {
      ...newConfigMapping,
      [id]: [{ name: updatedMeasure.Name, joinId: newColMeasure.joinId, entity: newColMeasure.entity }],
    };
  }

  return { newConfigMapping, newConfigColumns };
};
// Returns the updated report builder state on switch user field and filter filter
// @state: report builder state
// @payload: index and context of fields and filters
export const getStateOnSwitchUserFilter = (state:IReportBuilderState, payload:ISwitchUserFieldFilterPayload) => {
  const newState = state;
  const appliedDimensions = newState.updatedReportBuilderInfo.appliedDimensions;
  let newFilterConfig = newState.filterBuilder.filterConfig;
  const { Title, Description } = newState.updatedReportBuilderInfo.reportBuilderSavePayload.Details;

  const { filterIndex, fieldIndex } = getNewSwitchFieldFilterIndex(state, payload);

  newState.assortedInfo.isFormDisabled = getIsReportBuilderSaveFormDisable(Title, Description, state)
        || getIsFilterFormDisable(newState.filterBuilder);
  const newAppliedFilters = newState.filterBuilder.appliedFilters;
  const appliedUserFilter = newFilterConfig.find((filter) => filter.Type === FilterType.UserMultiSelect);
  const preAppliedUserFilter = newFilterConfig.find((filter) => filter.Type === FilterType.PRE_APPLIED && filter.DefaultValue === userDefaultFilterValue);
  if (appliedUserFilter) {
    delete newAppliedFilters[appliedUserFilter?.ID];
  }

  const dynamicFilter = isValidArrayAndNotEmpty(newState?.reportBuilderInfo?.additionalUserFieldFilters) && newState.reportBuilderInfo.additionalUserFieldFilters[filterIndex];

  newFilterConfig = isValidArrayAndNotEmpty(newFilterConfig) && newFilterConfig.filter(
    (config: IFilterConfig) => config.ID !== dynamicFilter?.ID,
  );
  newFilterConfig = isValidArrayAndNotEmpty(newFilterConfig) && newFilterConfig.filter(
    (config: IFilterConfig) => appliedUserFilter.ID !== config.ID,
  );
  if (dynamicFilter && !dynamicFilter?.LinkedTo) {
    dynamicFilter.LinkedTo = appliedUserFilter.LinkedTo;
    dynamicFilter.LinkedPreAppliedFilterId = preAppliedUserFilter?.ID || '';
    dynamicFilter.DataType = appliedUserFilter.DataType;
  }

  newFilterConfig.push(dynamicFilter);
  newAppliedFilters[dynamicFilter.ID] = new FilterFetchUtility(
    null,
  ).GetDefaultSingleFilter(dynamicFilter, null);
  const userMultiSelectFilterData = isValidArrayAndNotEmpty(newFilterConfig) && newFilterConfig.find(
    (config: IFilterConfig) => config.Type === FilterType.UserMultiSelect,
  );
  if (userMultiSelectFilterData) {
    newState.filterBuilder.isUsersModified = true;
    newAppliedFilters[userMultiSelectFilterData.ID] = new FilterFetchUtility(null).GetDefaultSingleFilter(
      userMultiSelectFilterData,
      null,
    );
  }
  newState.filterBuilder.filterConfig = newFilterConfig;
  newState.filterBuilder.appliedFilters = newAppliedFilters;

  if (!payload?.isSwitchFieldAdded && !(fieldIndex < 0)) {
    const appliedSwitchFieldIndex = appliedDimensions.findIndex((dim:IDimension) => dim?.Applied === DimensionStatus.Applied && dim?.IsFieldSwitch);
    if (appliedSwitchFieldIndex < 0) {
      return newState;
    }
    const appliedSwitchField = appliedDimensions[appliedSwitchFieldIndex];
    const dimension = appliedDimensions[fieldIndex];

    dimension.DimensionMode = appliedSwitchField.DimensionMode;

    dimension.Applied = DimensionStatus.Applied;
    appliedSwitchField.Applied = DimensionStatus.NotApplied;
    appliedDimensions[appliedSwitchFieldIndex] = dimension;
    appliedDimensions[fieldIndex] = appliedSwitchField;
  }

  return newState;
};

/**
 * Determines the new index for a switch field filter based on the current state and payload.
 *
 * @param state - The current state of the report builder
 * @param payload - The payload containing information about the switch operation
 * @returns An object with the new field index and filter index
 */
export const getNewSwitchFieldFilterIndex = (state:IReportBuilderState, payload:ISwitchUserFieldFilterPayload): {
  fieldIndex: number,
  filterIndex: number
} => {
  const { index, context } = payload;

  if (context === SwitchContext.Filters) {
    const appliedDimensions = state.updatedReportBuilderInfo.appliedDimensions;
    const filter = state.reportBuilderInfo.additionalUserFieldFilters[index];
    const fieldIndex = isValidArrayAndNotEmpty(appliedDimensions) && appliedDimensions.findIndex((item:IDimension) => item?.BuilderConfig?.Alias === filter.Alias && item?.BuilderConfig?.Entity === filter?.Entity);
    return {
      fieldIndex,
      filterIndex: index,
    };
  }

  const field = state.updatedReportBuilderInfo.appliedDimensions[index];
  const additionalUserFilters = state.reportBuilderInfo.additionalUserFieldFilters;
  const filterIndex = isValidArrayAndNotEmpty(additionalUserFilters) && additionalUserFilters?.findIndex((item:IFilterConfig) => item.Alias === field.BuilderConfig?.Alias && item?.Entity === field?.BuilderConfig?.Entity);
  return {
    fieldIndex: index,
    filterIndex,
  };
};

/**
 * Generates the dimension metafields payload for saving a report.
 *
 * @param dimennsionMetaFields - The existing dimension metafields.
 * @param dimensions - The list of all dimensions.
 * @returns An updated array of dimension metafields including the applied user dimension.
 */
export const getSavePayloadDimensionMetafields = (dimennsionMetaFields: IDimensionsMetaFields[], dimensions: IDimension[]): IDimensionsMetaFields[] => {
  const updatedDimenstionMetaFields = dimennsionMetaFields;
  const appliedUserDimension = dimensions.find((dim: IDimension) => dim.Applied === DimensionStatus.Applied && dim.IsFieldSwitch);
  if (!appliedUserDimension) return updatedDimenstionMetaFields;
  const isAlreadyExist = updatedDimenstionMetaFields.some((dim: IDimensionsMetaFields) => dim.Dimension === appliedUserDimension?.Name);
  if (isAlreadyExist) return updatedDimenstionMetaFields;

  const appliedDimensionMetaField:IDimensionsMetaFields = {
    Dimension: appliedUserDimension?.Name,
    Type: 'User',
    Fields: [{
      SchemaName: UserNameConstant,
      Alias: appliedUserDimension?.ReferTo,
    }],
  };
  updatedDimenstionMetaFields.push(appliedDimensionMetaField);

  return updatedDimenstionMetaFields;
};

export const getHierarchyField = (dimension: IDimension) => {
  const fieldName = dimension.Name.split(UserDelimiter);
  return fieldName[0] + UserHierarchy;
};

export const getReportHierarchy = (dimension: IDimension, hierarchy: IHierarchy) => {
  if (dimension) {
    return { ...hierarchy, DimensionAlias: dimension?.Name, HierarchyField: getHierarchyField(dimension) };
  }
  return hierarchy;
};
