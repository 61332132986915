/* eslint-disable no-param-reassign */
import moment from 'moment-timezone';
import {
  NumericDataTypes,
  defaultChartDimensions, defaultKPIDimensions, defaultRowHeight, defaultTableDimensions,
} from 'core/constants/dashboard';
import { VisualizationTypes } from 'core/constants/visualizations';
import {
  IDashboardVisualization, IFilterItem, IFilterLinkInfo, IPanel, IReportMappedFilters,
} from 'core/models/dashboard';
import { Obj } from 'core/models/obj';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { IColumn, IFiltersMetaData } from 'core/models/report-response';
import { ObjModel } from 'core/models';
import { FilterType } from 'core/constants/filter';
import { isValidArray, isValidArrayAndNotEmpty, isValidObjectAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { ColumnDataTypes } from 'core/constants/report';
import { IDateRangeTypes } from 'core/constants/date';
import { isMeasure, isMeasureDynamic } from './report.util';
import { getFormattedValues } from './formatters.utility';
import { getDatesIfRelativeRangeIsRestricted, getDatesIfAbsoluteRangeIsRestricted } from './date.util';

export const getDashboardLayoutDetails = ({
  Margin, IsDraggable, IsResizable, CompactType,
}: IDashboardVisualization) => ({
  margin: Margin,
  isDraggable: IsDraggable,
  isResizable: IsResizable,
  rowHeight: defaultRowHeight,
  compactType: CompactType,
});

export const getDefaultDimensions = (typeOfPanel: VisualizationTypes) => {
  switch (typeOfPanel) {
    case VisualizationTypes.KPI:
      return defaultKPIDimensions;
    case VisualizationTypes.Table:
      return defaultTableDimensions;
    case VisualizationTypes.BarChart:
    case VisualizationTypes.ColumnChart:
    case VisualizationTypes.LineChart:
    case VisualizationTypes.AreaChart:
    case VisualizationTypes.PieChart:
    case VisualizationTypes.DonutChart:
    case VisualizationTypes.EmbeddedUrl:
    default:
      return defaultChartDimensions;
  }
};

export const getPanelWidth = (currentWidth: number, panelType: VisualizationTypes) => {
  const defaultDimensions = getDefaultDimensions(panelType);
  let resWidth = currentWidth;
  if (resWidth < defaultDimensions.minW) {
    resWidth = defaultDimensions.minW;
  }
  if (resWidth > defaultDimensions.maxW) {
    resWidth = defaultDimensions.maxW;
  }
  return resWidth;
};

export const getPanelHeight = (currentHeight: number, panelType: VisualizationTypes) => {
  const defaultDimensions = getDefaultDimensions(panelType);
  let resHeight = currentHeight;
  if (resHeight < defaultDimensions.minH) {
    resHeight = defaultDimensions.minH;
  }
  if (resHeight > defaultDimensions.maxH) {
    resHeight = defaultDimensions.maxH;
  }
  return resHeight;
};

export const isColumnsEmpty = (panelCols:string[]) => (
  panelCols === null || panelCols?.length === 0
);

export const getEnumKey = (obj: any, value: any) => {
  const keyIndex = Object.values(obj).indexOf(value);
  return Object.keys(obj)[keyIndex];
};

export const getGlobalFilter = (globalFilters: Obj, panel: IPanel) => {
  const globalFiltersPayload:Obj = {};
  if (globalFilters) {
    Object?.entries(globalFilters).forEach((item) => {
      const result = getMappedFilterId(item[0], panel);
      if (result) {
        globalFiltersPayload[result] = item[1];
      }
    });
  }

  return globalFiltersPayload;
};

export const getMappedFilterId = (globalFilterId: string, panel: IPanel):string => {
  let returnValue = null;
  if (panel?.Source?.GlobalFiltersMapping) {
    Object?.entries(panel?.Source?.GlobalFiltersMapping)?.forEach((item) => {
      if (item[0] === globalFilterId) returnValue = item[1];
    });
  }

  return returnValue;
};

export const getFiltersPayload = (filters:IFilterItem[]) => {
  const globalFiltersObj:Obj = {};
  if (isValidArrayAndNotEmpty(filters)) {
    filters?.forEach((filter:IFilterItem) => {
      if (filter.Type === FilterType.UserMultiSelect) {
        globalFiltersObj[filter.ID] = filter?.filterData?.FilterResponse?.UserIds;
      } else if (filter.Type === FilterType.DateRange) {
        globalFiltersObj[filter.ID] = filter?.filterData?.FilterResponse;
      } else {
        const filterReponse = filter?.filterData?.FilterResponse;
        globalFiltersObj[filter.ID] = filterReponse;
      }
    });
  }
  return globalFiltersObj;
};

export const isPanelsFiltered = (dynamicGlobalFilters: IFilterItem[], staticGlobalFilters: IFilterItem[], globalFilter: Object) : boolean => {
  let value = false;

  if (isEqual(dynamicGlobalFilters, staticGlobalFilters)) {
    return false;
  }
  if (globalFilter) {
    const MappedFilters = Object.keys(globalFilter);

    dynamicGlobalFilters.forEach((item, index) => {
      if (MappedFilters.includes(item.ID) && !isEqual(dynamicGlobalFilters[index]?.filterData.FilterResponse, staticGlobalFilters[index]?.filterData.FilterResponse)) {
        value = true;
      }
    });
  }

  return value;
};

export const filterAppliedOnPanels = (staticGlobalFilters: IFilterItem[], dashboardConfigFilters: IFilterItem[], globalFilter: Object) => {
  const filtersApplied : string[] = [];
  if (isEqual(staticGlobalFilters, dashboardConfigFilters)) {
    return filtersApplied;
  }
  if (dashboardConfigFilters.length > staticGlobalFilters.length) {
    filtersApplied.push(dashboardConfigFilters.find((filter) => !staticGlobalFilters.includes(filter))?.Label);
  }
  if (globalFilter) {
    const MappedFilters = Object.keys(globalFilter);

    staticGlobalFilters.forEach((item, index) => {
      if (MappedFilters.includes(item.ID) && !isEqual(staticGlobalFilters[index]?.filterData?.FilterResponse, dashboardConfigFilters[index]?.filterData?.FilterResponse)) {
        filtersApplied.push(item.Label);
      }
    });
  }

  return filtersApplied;
};

export const filterAppliedOnDashboardBuilder = (dynamicGlobalFilters: IFilterItem[], globalFilter: Object) => {
  const filtersApplied : string[] = [];
  if (globalFilter) {
    const MappedFilters = Object.keys(globalFilter);

    dynamicGlobalFilters.forEach((item) => {
      if (MappedFilters.includes(item.ID)) {
        filtersApplied.push(item.Label);
      }
    });
  }

  return filtersApplied;
};
export const getPanelConfigMappedColumns = (panelFetchReportColumns:IColumn[], panelConfigColumns: string[]) => {
  let panelConfigMappedColumns:IColumn[] = [];
  const dimensions:IColumn[] = panelFetchReportColumns?.filter((colObj: IColumn) => !isMeasure(colObj));
  const measures:IColumn[] = [];
  panelConfigColumns?.forEach((panelCol:string) => {
    const panelColObj = panelFetchReportColumns?.find((col:IColumn) => col.Name === panelCol);
    if (isMeasure(panelColObj)) {
      measures.push(panelColObj);
    }
  });
  panelConfigMappedColumns = dimensions.concat(measures);
  return panelConfigMappedColumns;
};

export const getLinkedFilters = (GlobalFiltersMapping: Object, globalFiltersValue: ObjModel.Obj) => {
  if (isEmpty(GlobalFiltersMapping) || isEmpty(globalFiltersValue)) return null;
  const LinkedFilters: ObjModel.Obj = {};
  const globalFiltersID = Object.keys(GlobalFiltersMapping);

  globalFiltersID.forEach((filterID) => {
    const LinkedFilterID = GlobalFiltersMapping[filterID as keyof object];
    LinkedFilters[LinkedFilterID] = globalFiltersValue[filterID];
  });

  return LinkedFilters;
};

export const isMailMergeURL = (url: string) => url.includes('@{User:SecretKey ,}') || url.includes('@{User:AccessKey ,}');

export const getKPIValue = (grandTotals:Object, columns:IColumn[], selctedMeasure: string, userTimeZone: any) => {
  const selectedColumn = columns?.find((col) => col?.Name === selctedMeasure);
  const formatter = selectedColumn?.Props?.Formatter;
  const renderType = isMeasure(selectedColumn) ? selectedColumn?.Props?.Measure?.RenderType : selectedColumn?.BuilderConfig?.RenderType;
  let value: any = 0;
  if (grandTotals && selctedMeasure in grandTotals) {
    value = grandTotals[selctedMeasure as keyof typeof grandTotals];
    value = getFormattedValues(value, formatter, userTimeZone, false, renderType) || 0;
  }
  return value;
};
// Utilising filterId to fetch the next adjecent selected filterObj
// @linkedPanelFilter: object where panelId is the key and filterId is the value
// @panel: id of the selected panel
// @panelList: list of available panels
// @reportMappedFilters: object where reportId is the key and list of corresponding filters is the value
// @filterData: selectedFilter object
export const getFilterInfo = (linkedPanelFilter:IFilterLinkInfo, panelID:string, panelList:IPanel[], reportMappedFilters:IReportMappedFilters, filterData:IFilterItem) :IFilterItem => {
  const filterInfo = {
    ...filterData,
  };
  const remainingLinkedPanelIdsList = Object.keys(linkedPanelFilter).filter((item) => item !== panelID);
  const filterIdOfChosenPanel = isValidArray(remainingLinkedPanelIdsList) && remainingLinkedPanelIdsList.length > 0 ? linkedPanelFilter[remainingLinkedPanelIdsList[0]] : null;

  if (filterIdOfChosenPanel) {
    const reportId = panelList.find((item:IPanel) => item.Id === remainingLinkedPanelIdsList[0])?.Source?.SourceId;
    const filterItems = isValidObjectAndNotEmpty(reportMappedFilters) && Object.values(reportMappedFilters[reportId as keyof Object]).filter((filter:IFilterItem) => filter?.ID === filterIdOfChosenPanel);
    const isValidFilterList = isValidArray(filterItems) && filterItems.length > 0;
    filterInfo.Metadata = {
      FilterKey: isValidFilterList ? filterItems[0]?.Metadata?.FilterKey : '',
      DataSourcePanelId: remainingLinkedPanelIdsList[0],
    };
    filterInfo.DataType = isValidFilterList ? filterItems[0]?.DataType : ColumnDataTypes.Default;
  } else {
    filterInfo.Metadata = {};
  }
  return filterInfo;
};

// Get the FilterMetaInfo for UserMultiSelect Type filter for linked panel
// @GlobalFiltersMapping: object where panelId is the key and filterId is the value
// @globalFiltersValue: object where global filter is the key and linked report filter is the value
// @staticGlobalFilters: list of available panels
export const getLinkedUserFilterType = (GlobalFiltersMapping: Object, globalFiltersValue: ObjModel.Obj, staticGlobalFilters: IFilterItem[]): IFiltersMetaData => {
  if (isEmpty(GlobalFiltersMapping) || isEmpty(globalFiltersValue)) return null;
  const LinkedUserType: IFiltersMetaData = {
    UserDropdownSelectType: null,
    Status: null,
  };
  const globalFilterIDs = Object.keys(GlobalFiltersMapping);
  if (isValidArrayAndNotEmpty(globalFilterIDs)) {
    globalFilterIDs.forEach((filterID) => {
      const linkedUserFilter = staticGlobalFilters.find((filter) => filter.ID === filterID && filter.Type === FilterType.UserMultiSelect);
      if (linkedUserFilter) {
        LinkedUserType.UserDropdownSelectType = linkedUserFilter?.filterData?.FilterResponse?.Type;
        LinkedUserType.Status = linkedUserFilter?.Metadata?.Status;
      }
    });
  }
  return LinkedUserType.UserDropdownSelectType ? LinkedUserType : null;
};

export const getMeasureFieldAlias = (selectedMeasure: IColumn) => (isMeasureDynamic(selectedMeasure) ? selectedMeasure.BuilderConfig.Alias : '');

// Detect the change in filter Response
export const isFilterChanged = (staticGlobalFilters: IFilterItem[], dynamicGlobalFilters: IFilterItem[]) => {
  const staticFilters = cloneDeep(staticGlobalFilters) || [];
  const dynamicFilters = cloneDeep(dynamicGlobalFilters) || [];

  // Remove FilterOptions and Options Meta for the comparission
  if (isValidArray(staticFilters) && isValidArray(dynamicFilters)) {
    staticFilters?.forEach((filter) => {
      if (filter.filterData) {
        filter.filterData.FilterOptions = undefined;
        filter.filterData.OptionsMeta = undefined;
      }
    });
    dynamicFilters?.forEach((filter) => {
      if (filter.filterData) {
        filter.filterData.FilterOptions = undefined;
        filter.filterData.OptionsMeta = undefined;
      }
    });
    return isEqual(staticFilters, dynamicFilters);
  }
  return true;
};
// to validate if the given curDataType is same as the selectedDataType (applicable only for string and Int)
// @selectedDataType: datatype w.r.t which the validation is done
// @curDataType: datatype for which the validation is done
export const validateStringAndIntDatatypes = (selectedDataType:string, curDataType:string) :boolean => {
  if (selectedDataType === ColumnDataTypes.String) {
    return selectedDataType === curDataType;
  }
  return NumericDataTypes.includes(curDataType);
};

// Update the filter value if it is a partition filter and returns the filter object with the updated value.
// @filter: dashboard filter of which filter value needs to be updated.
export const updatePartitionFilterValue = (filter: IFilterItem, partitionColumnFilterDateLimit: number): IFilterItem => {
  const newFilter = cloneDeep(filter);
  if (newFilter?.filterData?.FilterResponse?.Type === IDateRangeTypes.Relative) {
    newFilter.filterData.FilterResponse = getDatesIfRelativeRangeIsRestricted(newFilter?.filterData?.FilterResponse?.Value, partitionColumnFilterDateLimit);
  } else {
    newFilter.filterData.FilterResponse = getDatesIfAbsoluteRangeIsRestricted(moment(newFilter?.filterData?.FilterResponse?.Value?.From),
      moment(newFilter?.filterData?.FilterResponse?.Value?.To), partitionColumnFilterDateLimit);
  }
  return newFilter;
};
